import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

/**
@function CustomTextField
@description - building UI of TextField
@param {object} props - App props accept all that support in TextField
@param {object} errorMessege - pass validation message for error and helperText
@returns {JSX} CustomTextField UI
@example <CustomTextField variant="outlined" />
 */

/** */
const CustomTextField = ({ errorMessege, ...props }) => {
  return (
    <>
      <TextField
        id="Custom-TextField"
        {...props}
        // eslint-disable-next-line react/prop-types
        variant={props.variant ? props.variant : 'outlined'}
        // eslint-disable-next-line react/prop-types
        value={props.value ?? ''}
        helperText={errorMessege}
        error={Boolean(errorMessege)}
      />
    </>
  );
};

export default React.memo(CustomTextField);
CustomTextField.propTypes = {
  errorMessege: PropTypes.string.isRequired,
  props: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
