// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth, selectPanel } from '../state/slices/appSlice';

const useUser = () => {
  const { user } = useSelector(selectAuth);
  const { panelNavigation } = useSelector(selectPanel);
  const [profileUpdated, setprofileUpdated] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * @function updateProfile
   * @description A function to update user's profile
   * @returns {Promise} returns an axios promise
   */
  const updateProfile = (data) => {
    const param = { ...data };
    if (param.listType && Array.isArray(param.listType)) {
      param.listType.forEach((item) => {
        param[item] = true;
      });
      delete param.listType;
    }

    setIsLoading(true);
    return axios.patch(API_ENDPOINTS.PROFILE_UPDATE, param).then(
      (response) => {
        setIsLoading(false);
        enqueueSnackbar(response?.data?.Msg, {
          variant: 'success',
        });
        if (response?.data) {
          setprofileUpdated(response?.data);
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function getUserData
   * @description A function to fetch user's data
   * @returns {Promise} returns an axios promise
   */
  const getUserData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.GET_USER_DATA, { loader: false }).then(
      (response) => {
        if (response?.data) {
          setUserData(response?.data);
          setIsLoading(false);
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  return {
    isError,
    isLoading,
    profileUpdated,
    updateProfile,
    getUserData,
    userData,
    panelNavigation,
    user,
  };
};
export default useUser;
