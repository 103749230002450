// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { MuiDatePicker } from 'solytics-frontend';
import { Constants } from '../../const/index';
import useAuth from 'hooks/useAuth';

const { Regex } = Constants;
const today = moment().format('YYYY-MM-DD');
const formValidationSchema = Yup.object({
  date_of_birth: Yup.date()
    .max(today, "Date cannot be greater than today's date")
    .nullable()
    .typeError('Invalid Date!'),
  email: Yup.string('Enter your email').email('Enter a valid email'),
  phone_no: Yup.string()
    .matches(Regex.PHONE_NO, 'Phone number is not valid')
    .min(5, ' Phone No. too short')
    .max(18, 'Phone No. too long')
    .nullable(),
  alternate_email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .nullable(),
  passport_number: Yup.string()
    .matches(Regex.PASSPORT, 'Passport no is not valid')
    .nullable(),
});
/**
 @function ViewProfile
@description - A form to view and update profile
@param {boolean} profileOpen - A boolean value to open the profile form
@param {function} SubmitProfileForm - a function to close the form while clicking on submit button
@param {function} handleProfileClose - a function to close the form while clicking on close icon
@param {object} userData - object which contains user data
@param {boolean} isLoading - boolean value for loading state
@param {function} updateProfile - a function to patch the updated value
@returns {JSX} ViewProfile UI
@example <ViewProfile />
 */
export default function ViewProfile({
  profileOpen,
  SubmitProfileForm,
  handleProfileClose,
  userData,
  isLoading,
  updateProfile,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {logout} = useAuth();
  // object to initialize the form value
  const initialValues = {
    username: userData.username,
    gender: userData.gender,
    date_of_birth: userData.date_of_birth,
    first_name: userData.first_name,
    last_name: userData.last_name,
    email: userData.email,
    phone_no: userData.phone_no,
    alternate_email: userData.alternate_email,
    user_role: userData.user_role,
    user_type: userData.user_type,
    pageAccess: userData.pageAccess,
    branch: userData.branch,
    address: userData.address,
    drivers_licence_number: userData.drivers_licence_number,
    ssn: userData.ssn,
    passport_number: userData.passport_number,
    checkVerified: false,
  };
  // form for updating the profile
  const profileForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      if (values?.checkVerified) {
        updateProfile({
          ...values,
          date_of_birth:
            values?.date_of_birth !== null
              ? moment(values?.date_of_birth).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
              : null,
        }).then(() => {
          if (userData.email !== values?.email) {
            logout()
          }
        });
        handleProfileClose(true);
      } else {
        enqueueSnackbar('Please verify the details to proceed', {
          variant: 'error',
        });
      }
    },
  });
  // function to handle date change
  const handleChangeDate = (date) => {
    profileForm.setFieldValue('date_of_birth', date);
  };

  return (
    <>
      {!isLoading && (
        <Dialog
          open={profileOpen}
          onClose={SubmitProfileForm}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <form onSubmit={profileForm.handleSubmit}>
            <DialogTitle id="scroll-dialog-title">
              <Box display="flex" justifyContent="space-between">
                <Box mt={1}>
                  <Typography color="primary" variant="subtitle1">
                    Profile
                  </Typography>
                </Box>
                <Box mr={2}>
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={handleProfileClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent dividers={true}>
              <Box>
                <Grid container spacing={3}>
                  <Grid container item direction="row" xs={12} spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="userName"
                        name="username"
                        label="User Name"
                        disabled
                        variant="outlined"
                        color="primary"
                        onChange={profileForm.handleChange}
                        value={
                          profileForm.values.username
                            ? profileForm.values.username
                            : ''
                        }
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="gender"
                        name="gender"
                        label=" Gender"
                        variant="outlined"
                        select
                        color="primary"
                        value={
                          profileForm.values.gender
                            ? profileForm.values.gender
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <MuiDatePicker
                        label="DOB"
                        size="small"
                        name="date_of_birth"
                        format="yyyy/MM/DD"
                        mask="____/__/__"
                        maxDate={new Date()}
                        value={profileForm.values.date_of_birth}
                        error={Boolean(profileForm.errors.date_of_birth)}
                        helperText={profileForm.errors.date_of_birth}
                        onChange={handleChangeDate}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" xs={12} spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="firstName"
                        name="first_name"
                        disabled
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.first_name
                            ? profileForm.values.first_name
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="lastName"
                        name="last_name"
                        label="Last Name"
                        disabled
                        fullWidth
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.last_name
                            ? profileForm.values.last_name
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" xs={12} spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.email
                            ? profileForm.values.email
                            : ''
                        }
                        error={Boolean(profileForm.errors.email)}
                        helperText={profileForm.errors.email}
                        onChange={profileForm.handleChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="phone"
                        name="phone_no"
                        label="Phone"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        value={
                          profileForm.values.phone_no
                            ? profileForm.values.phone_no
                            : ''
                        }
                        error={Boolean(profileForm.errors.phone_no)}
                        helperText={profileForm.errors.phone_no}
                        onChange={profileForm.handleChange}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="altEmail"
                        name="alternate_email"
                        label="Alternate email Address"
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.alternate_email
                            ? profileForm.values.alternate_email
                            : ''
                        }
                        error={Boolean(profileForm.errors.alternate_email)}
                        helperText={profileForm.errors.alternate_email}
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" xs={12} spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                      <Grid />
                      <TextField
                        id="userRole"
                        name="user_role"
                        label="User Role"
                        disabled
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.user_role
                            ? profileForm.values.user_role
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      />
                      <Box mt={3}>
                        <TextField
                          id="userType"
                          name="user_type"
                          label="User Type"
                          disabled
                          variant="outlined"
                          color="primary"
                          value={
                            profileForm.values.user_type
                              ? profileForm.values.user_type
                              : ''
                          }
                          onChange={profileForm.handleChange}
                          size="small"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="pageAccess"
                        name="pageAccess"
                        label="Page Access"
                        disabled
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.pageAccess
                            ? profileForm.values.pageAccess
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      />
                      <Box mt={3}>
                        <TextField
                          id="branch"
                          name="branch"
                          label="Branch"
                          disabled
                          variant="outlined"
                          color="primary"
                          value={
                            profileForm.values.branch
                              ? profileForm.values.branch
                              : ''
                          }
                          onChange={profileForm.handleChange}
                          size="small"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="remarks"
                        name="address"
                        label="Remarks"
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.address
                            ? profileForm.values.address
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        multiline
                        rows={4}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" xs={12} spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="driversLicense"
                        name="drivers_licence_number"
                        label="Drivers License"
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.drivers_licence_number
                            ? profileForm.values.drivers_licence_number
                            : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="ssn"
                        name="ssn"
                        label="Social Security Number"
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.ssn ? profileForm.values.ssn : ''
                        }
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                      <TextField
                        id="passportNo"
                        name="passport_number"
                        label="Passport Number"
                        variant="outlined"
                        color="primary"
                        value={
                          profileForm.values.passport_number
                            ? profileForm.values.passport_number
                            : ''
                        }
                        error={Boolean(profileForm.errors.passport_number)}
                        helperText={profileForm.errors.passport_number}
                        onChange={profileForm.handleChange}
                        size="small"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
              >
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={Boolean(profileForm.values.checkVerified)}
                      color="primary"
                      name="checkVerified"
                      onChange={profileForm.handleChange}
                    />
                    <Typography variant="subtitle2">
                      I agree that the above details are correct and verified
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    // onClick={profileForm.handleSubmit}
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save and Continue
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
}
ViewProfile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleProfileClose: PropTypes.func.isRequired,
  SubmitProfileForm: PropTypes.func.isRequired,
  profileOpen: PropTypes.bool.isRequired,
};
