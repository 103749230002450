// @ts-nocheck
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';

/**
 * @function useAdmin
 * @description a custom hook which handles APIs related to admin dashboard
 * @returns {object} object
 */
const useAdmin = () => {
  const { user } = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [organisationRowData, setOrganisationRowData] = useState({});
  const [organisationData, setOrganisationData] = useState([]);
  const [screen, setScreen] = useState('admin');
  const [groupList, setGroupList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [groupData, setGroupData] = useState({});
  const [userRowData, setUserRowData] = useState({});
  const [userList, setUserList] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [sanctions, setSanctions] = useState([]);
  const [bagOfWords, setBagOfWords] = useState([]);
  const [totalbagOfWords, setTotalbagOfWords] = useState(0);
  const [bagOfWordsPageCount, setBagOfWordsPageCount] = useState(0);
  const [bagOfWordsFetched, setBagOfWordsFetched] = useState(false);
  const [tustedSources, setTustedSources] = useState([]);
  const [totaltustedSources, setTotalTustedSources] = useState(0);
  const [tustedSourcesPageCount, setTustedSourcesPageCount] = useState(0);
  const [tustedSourcesFetched, setTustedSourcesFetched] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(5);
  const [bowScreen, setBowScreen] = useState('bow');
  const dbName = { db_name: selectedOrganisation };
  /**
   * @function fetchOrganisationDetails
   * @description A function to fetch Organisation Details
   * @returns {Promise} returns an axios promise
   */
  const fetchOrganisationDetails = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_LIST).then(
      (response) => {
        setIsLoading(false);
        setOrganisationData(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchGroupList
   * @description A function to fetch group list
   * @returns {Promise} returns an axios promise
   */
  const fetchGroupList = () => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_GROUPS, {
        params: dbName,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setGroupList(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchPermissions
   * @description A function to fetch permissions list
   * @returns {Promise} returns an axios promise
   */
  const fetchPermissions = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ADMIN_DASHBOARD_PERMISSION_GROUPS).then(
      (response) => {
        setIsLoading(false);
        setPermissionsList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchUserData
   * @description A function to fetch user's data
   * @returns {Promise} returns an axios promise
   */
  const fetchUserData = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.ADMIN_DASHBOARD_USER, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setUserList(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchOrgSanctionConfiguration
   * @description A function to fetch sanctions list for an organisation
   * @returns {Promise} returns an axios promise
   */
  const fetchOrgSanctionConfiguration = (data) => {
    setIsLoading(true);
    return axios
      .get(
        `${API_ENDPOINTS.ADMIN_DASHBOARD_SANCTION_CONFIGURATION}${data?.dbName}/`
      )
      .then(
        (response) => {
          setIsLoading(false);
          setSanctions(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateOrgSanctionConfiguration
   * @description A function to update sanction list
   * @returns {Promise} returns an axios promise
   */
  const updateOrgSanctionConfiguration = (data) => {
    const body = {
      sanction_list: data?.list,
    };
    setIsLoading(true);
    return axios
      .post(
        `${API_ENDPOINTS.ADMIN_DASHBOARD_SANCTION_CONFIGURATION}${data.dbName}/`,
        body
      )
      .then(
        (response) => {
          setIsLoading(false);
          enqueueSnackbar('Sanctions List Updated successfully', {
            variant: 'success',
          });
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  useEffect(() => {
    fetchOrganisationDetails();
    fetchGroupList();
    fetchPermissions();
  }, [screen, selectedOrganisation]);
  /**
   * @function fetchGroupData
   * @description A function to fetch group data
   * @returns {Promise} returns an axios promise
   */
  const fetchGroupData = (data) => {
    setIsLoading(true);
    return axios
      .get(`${API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_GROUPS}${data}/`)
      .then(
        (response) => {
          setIsLoading(false);
          setGroupData(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function createNewGroup
   * @description A function to post data for created group
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const createNewGroup = (data) => {
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_GROUPS, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Group created Successfully', {
            variant: 'success',
          });
          setScreen('admin');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateGroup
   * @description A function to update the group
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const updateGroup = (data) => {
    setIsLoading(true);
    return axios
      .put(
        `${API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_GROUPS}${data?.id}/`,
        data
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Group updated Successfully', {
            variant: 'success',
          });
          setScreen('admin');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteGroup
   * @description A function to delete group data'
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const deleteGroup = (data) => {
    const params = {
      id: data.id,
    };
    setIsLoading(true);
    return axios
      .delete(
        `${API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_GROUPS}${params?.id}/`
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Group deleted successfully', {
            variant: 'success',
          });
          fetchGroupList();
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function createNewOrganisation
   * @description A function to create New Organisation
   * @returns {Promise} returns an axios promise
   */
  const createNewOrganisation = (data) => {
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_LIST, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Organisation created Successfully', {
            variant: 'success',
          });
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateOrganisation
   * @description A function to update Organisation details
   * @returns {Promise} returns an axios promise
   */
  const updateOrganisation = (data) => {
    setIsLoading(true);
    return axios
      .put(
        `${API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_LIST}${data?.id}/`,
        data
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Organisation updated Successfully', {
            variant: 'success',
          });
          setScreen('admin');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteOrganisation
   * @description A function to delete Organisation
   * @returns {Promise} returns an axios promise
   */
  const deleteOrganisation = (data) => {
    setIsLoading(true);
    return axios
      .delete(`${API_ENDPOINTS.ADMIN_DASHBOARD_ORGANISATION_LIST}${data?.id}/`)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Organisation deleted successfully', {
            variant: 'success',
          });
          fetchOrganisationDetails();
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function createNewUser
   * @description A function to create new users
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const createNewUser = (data) => {
    setIsLoading(true);
    return axios
      .post(
        `${API_ENDPOINTS.ADMIN_DASHBOARD_MANAGER}${selectedOrganisation}/${data.userType}/`,
        data
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Manager registered successfully', {
            variant: 'success',
          });
          setScreen('admin');
          fetchUserData(dbName);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateUser
   * @description A function to upadate user's data'
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const updateUser = (data) => {
    setIsLoading(true);
    return axios
      .patch(`${API_ENDPOINTS.ADMIN_DASHBOARD_USER}${data?.id}/`, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Manager updated successfully', {
            variant: 'success',
          });
          setScreen('admin');
          fetchUserData(dbName);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteUser
   * @description A function to delete user's data'
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const deleteUser = (data) => {
    setIsLoading(true);
    return axios
      .delete(`${API_ENDPOINTS.ADMIN_DASHBOARD_USER}${data?.id}/`, {
        params: dbName,
      })
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Manager deleted successfully', {
            variant: 'success',
          });
          fetchUserData(dbName);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchAdminBagOfWords
   * @description A function to fetch admin bag of words
   * @param {number} page - page index
   * @param {number} pageSize - page size
   * @returns {Promise} returns an axios promise
   */
  const fetchAdminBagOfWords = useCallback((page = 0, pageSize = 5) => {
    setIsLoading(true);
    setCurrPage(page);
    setCurrPageSize(pageSize);
    return axios
      .get(API_ENDPOINTS.ADMIN_BAG_OF_WORDS, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setBagOfWords([...response?.data]);
          setTotalbagOfWords(response.headers['x-total-count']);
          setBagOfWordsPageCount(
            Math.ceil(response.headers['x-total-count'] / pageSize)
          );
          setBagOfWordsFetched(true);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  }, []);
  /**
   * @function deleteWords
   * @description A function to delete bag of words
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const deleteWords = (data) => {
    const param = {
      uids: [data.uid],
    };
    setIsLoading(true);
    return axios.delete(API_ENDPOINTS.ADMIN_BAG_OF_WORDS, { data: param }).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('AML Taxonomy deleted successfully', {
          variant: 'success',
        });
        setTimeout(() => {
          fetchAdminBagOfWords(currPage, currPageSize);
        }, 1000);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function addBagOfWords
   * @description A function to add bag of words
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const addBagOfWords = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.ADMIN_BAG_OF_WORDS, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
          enqueueSnackbar('New words added Successfully', {
            variant: 'success',
          });
          setTimeout(() => {
            fetchAdminBagOfWords(currPage, currPageSize);
          }, 1000);
        }
        setBagOfWordsFetched(false);
        setTimeout(() => {
          setBagOfWordsFetched(true);
        }, 100);
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateBagOfWords
   * @description A function to update bag of words
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const updateBagOfWords = (data) => {
    setIsLoading(true);
    return axios.patch(API_ENDPOINTS.ADMIN_BAG_OF_WORDS, data).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Word updated successfully', {
          variant: 'success',
        });
        setBowScreen('bow');
        fetchAdminBagOfWords();
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchAdminTustedSources
   * @description A function to fetch admin trusted sources
   * @param {number} page - page index
   * @param {number} pageSize - page size
   * @returns {Promise} returns an axios promise
   */
  const fetchAdminTustedSources = useCallback((page = 0, pageSize = 5) => {
    setIsLoading(true);
    setCurrPage(page);
    setCurrPageSize(pageSize);
    return axios
      .get(API_ENDPOINTS.ADMIN_TRUSTED_SOURCES, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setTustedSources([...response?.data]);
          setTotalTustedSources(response.headers['x-total-count']);
          setTustedSourcesPageCount(
            Math.ceil(response.headers['x-total-count'] / pageSize)
          );
          setTustedSourcesFetched(true);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  }, []);
  /**
   * @function deleteTrustedSources
   * @description A function to delete trusted sources
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const deleteTrustedSources = (data) => {
    const param = {
      uids: [data.uid],
    };
    setIsLoading(true);
    return axios
      .delete(API_ENDPOINTS.ADMIN_TRUSTED_SOURCES, { data: param })
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Link deleted successfully', {
            variant: 'success',
          });
          setTimeout(() => {
            fetchAdminTustedSources(currPage, currPageSize);
          }, 1000);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function addTrustedSources
   * @description A function to add trusted sources
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const addTrustedSources = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.ADMIN_TRUSTED_SOURCES, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
          enqueueSnackbar('Link Added Successfully', {
            variant: 'success',
          });
          setTimeout(() => {
            fetchAdminTustedSources(currPage, currPageSize);
          }, 1000);
        }
        setTustedSourcesFetched(false);
        setTimeout(() => {
          setTustedSourcesFetched(true);
        }, 100);
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateTrustedSources
   * @description A function to update trusted sources
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const updateTrustedSources = (data) => {
    setIsLoading(true);
    return axios.patch(API_ENDPOINTS.ADMIN_TRUSTED_SOURCES, data).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Link updated successfully', {
          variant: 'success',
        });
        setBowScreen('bow');
        fetchAdminTustedSources();
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  return {
    user,
    isError,
    isLoading,
    screen,
    setScreen,
    organisationData,
    organisationRowData,
    setOrganisationRowData,
    createNewOrganisation,
    updateOrganisation,
    deleteOrganisation,
    fetchGroupData,
    groupList,
    permissionsList,
    groupData,
    createNewGroup,
    updateGroup,
    deleteGroup,
    userRowData,
    setUserRowData,
    createNewUser,
    deleteUser,
    userList,
    updateUser,
    fetchUserData,
    selectedOrganisation,
    setSelectedOrganisation,
    setSanctions,
    sanctions,
    updateOrgSanctionConfiguration,
    fetchOrgSanctionConfiguration,
    fetchAdminBagOfWords,
    bagOfWords,
    deleteWords,
    addBagOfWords,
    currPageSize,
    currPage,
    bagOfWordsFetched,
    bagOfWordsPageCount,
    totalbagOfWords,
    bowScreen,
    setBowScreen,
    updateBagOfWords,
    fetchAdminTustedSources,
    deleteTrustedSources,
    addTrustedSources,
    updateTrustedSources,
    tustedSourcesFetched,
    tustedSourcesPageCount,
    totaltustedSources,
    tustedSources,
    fetchOrganisationDetails,
  };
};

export default useAdmin;
