import axios from 'axios';
import { useState } from 'react';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import { ExcelRenderer } from 'react-excel-renderer';
import { useSnackbar } from 'notistack';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import useBulkFileUpload from './useBulkFileUpload';
import { Utils } from '../utils';
import { Constants } from '../const';

const { TRANSACTION_SCREENING_COLUMNS } = Constants.CommonConst;

export default function useTransactionScreening() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    onCompleteMultipartUpload,
    fetchPresignedUrlForEachChunk,
    initiateFileUpload,
  } = useBulkFileUpload();
  const [transactionScreeningConfig, setTransactionScreeningConfig] =
    useState(null);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionFileData, setTransactionFileData] = useState(null);
  const [productFileData, setProductFileData] = useState(null);
  const [channelFileData, setChannelFileData] = useState(null);
  const [channel, setChannel] = useState({});
  const [transactionType, setTransactionType] = useState({});
  const [productType, setProductType] = useState({});

  const fetchTransactionScreeningConfig = (data) => {
    setIsLoading(false);
    return axios
      .get(API_ENDPOINTS.TRANSACTION_SCREENING_RULE_ENGINE, { params: data })
      .then((response) => {
        setTransactionScreeningConfig(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(error);
      });
  };
  const updateTransactionScreeningConfig = (data) => {
    setIsLoading(false);
    return axios
      .post(API_ENDPOINTS.TRANSACTION_SCREENING_RULE_ENGINE, data)
      .then(() => {
        // setTransactionScreeningConfig(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(error);
      });
  };
  const {
    startUpload: startTransactionBulkUpload,
    progress: transactionBulkProgress,
  } = useMultipartFileUploadToS3({
    startUploadCallback: initiateFileUpload,
    fetchPresignedUrlForEachChunk,
    onCompleteMultipartUpload,
  });
  const { startUpload: startChannelBulkUpload, progress: channelBulkProgress } =
    useMultipartFileUploadToS3({
      startUploadCallback: initiateFileUpload,
      fetchPresignedUrlForEachChunk,
      onCompleteMultipartUpload,
    });
  const { startUpload: startProductBulkUpload, progress: productBulkProgress } =
    useMultipartFileUploadToS3({
      startUploadCallback: initiateFileUpload,
      fetchPresignedUrlForEachChunk,
      onCompleteMultipartUpload,
    });
  const verifyRiskRatingBulkFile = (file, fileType) => {
    return ExcelRenderer(file).then(
      (resp) => {
        const cols = resp.rows[0];
        if (file?.type === 'application/vnd.oasis.opendocument.spreadsheet') {
          return;
        }
        return Utils.CoreUtils.validateFileColumns(
          cols,
          TRANSACTION_SCREENING_COLUMNS[fileType]
        );
      },
      (err) => {
        enqueueSnackbar('Error while processing the file', {
          variant: 'error',
        });
        throw err;
      }
    );
  };
  const uploadParameterFile = (payload) => {
    return axios
      .patch(API_ENDPOINTS.TRANSACTION_SCREENING_RULE_ENGINE, payload)
      .then((res) => {
        enqueueSnackbar('Bulk file uploaded successfully.', {
          variant: 'success',
        });
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(
          'An error occurred while uploading the file. Please try again later.',
          {
            variant: 'error',
          }
        );
        throw error;
      });
  };
  /**
* @function getTransactionParameters
* @description A function to get transaction parameters
* @returns {Promise} returns an axios promise
*/
  const getTransactionParameters = (queryParams) => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.TRANSACTION_SCREENING_RULE_ENGINE, {
      params: queryParams,
    }).then(
      (response) => {
        setIsLoading(false);
        response?.data?.data?.map((item) => {
          if (item?.name === "channel_type") {
            setChannel({ values: item?.values, mandatory: item?.mandatory });
          }
          if (item?.name === "transaction_type") {
            setTransactionType({ values: item?.values, mandatory: item?.mandatory });
          }
          if (item?.name === "transaction_product_type") {
            setProductType({ values: item?.values, mandatory: item?.mandatory });
          }
        })
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };
  const deleteParameterFile = (data) => {
    return axios
      .delete(API_ENDPOINTS.TRANSACTION_SCREENING_RULE_ENGINE, { data })
      .then((res) => {
        enqueueSnackbar('Bulk file deleted successfully.', {
          variant: 'success',
        });
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(
          'An error occurred while uploading the file. Please try again later.',
          {
            variant: 'error',
          }
        );
        throw error;
      });
  };
  return {
    updateTransactionScreeningConfig,
    fetchTransactionScreeningConfig,
    transactionScreeningConfig,
    isLoading,
    transactionBulkProgress,
    startTransactionBulkUpload,
    channelBulkProgress,
    startChannelBulkUpload,
    productBulkProgress,
    startProductBulkUpload,
    transactionFileData,
    setTransactionFileData,
    productFileData,
    setProductFileData,
    channelFileData,
    setChannelFileData,
    verifyRiskRatingBulkFile,
    uploadParameterFile,
    deleteParameterFile,
    channel,
    transactionType,
    productType,
    getTransactionParameters,
  };
}
