// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';

const initialStackChartConf = {
  levels: ['Year', 'Month', 'Week', 'Day'],
  activeLevelIndex: 0,
};
const initialLineChartConf = {
  levels: ['Year', 'month', 'week'],
  activeLevelIndex: 0,
};
/**
 * @function useDashBoard
 * @description a custom hook which handles APIs related to dashboard screen
 * @returns {object} object
 */
export default function useDashBoard() {
  const [stats, setStats] = useState([]);
  const [stackChartData, setStackChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [reviewHistory, setReviewHistory] = useState([]);
  const [teamList, setTeamList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [searchData, setSearchData] = useState([]);
  const [analystType, setAnalystType] = useState([]);
  const [historyPageCount, setHistoryPageCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector(selectAuth);

  // stack chart variables
  const [stackChartConf, setStackChartConfig] = useState(initialStackChartConf);
  // line chart variables
  const [lineChartConf, setLineChartConfig] = useState(initialLineChartConf);
  // const User History Data (Summary)
  const [userHistorySummary, setUserHistorySummary] = useState([
    {
      details: [],
      sanctionsList: [],
      sanctionsCountryList: [],
      peplist: [],
      amsList: [],
    },
  ]);
  /**
   * @function fetchStats
   * @description A function to fetch dashboard stats
   * @returns {Promise} returns an axios promise
   */
  const fetchStats = (queryParams) => {
    setStackChartConfig(initialStackChartConf);
    setLineChartConfig(initialLineChartConf);
    setStats([]);
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.DASHBOARD_STATS, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setStats(response?.data);
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchHistory
   * @description A function to fetch user's history
   * @returns {Promise} returns an axios promise
   */

  const fetchHistory = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.REVIEW_HISTORY, {
        params: queryParams,
      })
      .then(
        (response) => {
          setHistoryPageCount(response?.headers['x-total-count']);
          setIsLoading(false);
          if (response?.data) {
            setReviewHistory(response?.data);
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function userTypes
   * @description A function to fetch user's type
   * @returns {Promise} returns an axios promise
   */
  const userTypes = (queryParams) => {
    setAnalystType([]);
    return axios.get(API_ENDPOINTS.DASHBOARD_USER_TYPE,
      {
        params: queryParams,
      }
    ).then(
      (response) => {
        if (response.length !== 0) {
          setAnalystType(response.data);
        }
      },
      () => {}
    );
  };
  /**
 * @function fetchTeamData
 * @description A function to fetch team data
 * @returns {Promise} returns an axios promise
 */
  const fetchTeamData = (queryParams) => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.INITIATE_SCREEN_TEAM_DATA, {
      params: queryParams,
    }).then(
      (response) => {
        setIsLoading(false);
        setTeamList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchBatchInfo
   * @description A function to fetch batch information
   * @returns {Promise} returns an axios promise
   */
  const fetchBatchInfo = (params) => {
    return axios
      .get(API_ENDPOINTS.DASHBOARD_BATCH_INFO, {
        params: {
          page: params.pageIndex + 1,
          pagesize: params.pageSize,
          manager_id: params?.manager_id,
          screen_type: params?.screen_type
        },
        // @ts-ignore
        loader: false,
      })
      .then(
        (response) => {
          setIsLoading(false);
          return response;
        },
        (e) => {
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchStackChartDataForDrilldown
   * @description A function to fetch data of stack chart for drill down
   * @returns {Promise} returns an axios promise
   */
  const fetchStackChartDataForDrilldown = (queryParams, chartData) => {
    if (stackChartConf.activeLevelIndex < 3) {
      const nextActiveIndex = stackChartConf.activeLevelIndex + 1;
      const nextActiveLevel = stackChartConf.levels[nextActiveIndex];
      const activeLevel =
        stackChartConf.levels[stackChartConf.activeLevelIndex];
      const decidingParameter =
        chartData !== undefined ? nextActiveLevel.toLowerCase() : 'year';

      const year =
        chartData !== undefined
          ? activeLevel === 'Year'
            ? chartData.indexValue
            : stackChartData?.stacked_chart?.Month?.[0]?.id
          : null;
      const month =
        chartData !== undefined
          ? activeLevel === 'Month'
            ? chartData.indexValue
            : stackChartData?.stacked_chart?.Week?.[0]?.id
            ? stackChartData?.stacked_chart?.Week?.[0]?.id
            : ''
          : null;
      const week =
        chartData !== undefined
          ? activeLevel === 'Week'
            ? chartData.indexValue.split('_')[1]
            : ''
          : null;
      // eslint-disable-next-line no-param-reassign
      queryParams = {
        decidingParameter,
        year,
        month: month ? moment().month(month).format('M') : '',
        week,
        ...queryParams,
      };

      setIsLoading(true);
      return axios
        .get(API_ENDPOINTS.DASHBOARD_STACK_CHART_DRILLDOWN, {
          params: queryParams,
        })
        .then(
          (response) => {
            setIsLoading(false);
            if (chartData !== undefined) {
              setStackChartData({
                stacked_chart: {
                  ...stackChartData.stacked_chart,
                  [nextActiveLevel]: response.data[nextActiveLevel],
                },
              });
              setStackChartConfig({
                ...stackChartConf,
                activeLevelIndex: nextActiveIndex,
              });
            } else {
              setStackChartData({
                // ...stats,
                stacked_chart: {
                  ...response?.data,
                },
              });
            }
          },
          () => {
            setIsLoading(false);
            // setIsError(e);
          }
        );
    }
  };
  /**
   * @function fetchLineChartDataForDrilldown
   * @description A function to fetch data of linr chart for drill down
   * @returns {Promise} returns an axios promise
   */
  const fetchLineChartDataForDrilldown = (queryParams, chartData) => {
    if (lineChartConf.activeLevelIndex < 3) {
      const nextActiveIndex = lineChartConf.activeLevelIndex + 1;
      const nextActiveLevel = lineChartConf.levels[nextActiveIndex];
      const activeLevel = lineChartConf.levels[lineChartConf.activeLevelIndex];
      const decidingParameter =
        chartData !== undefined ? nextActiveLevel.toLowerCase() : 'year';

      const year =
        chartData !== undefined
          ? activeLevel === 'Year'
            ? chartData.data.x
            : lineChartData.target_miss.month?.[0]?.id
          : null;
      const month =
        chartData !== undefined
          ? activeLevel === 'month'
            ? chartData.data.x
            : lineChartData.target_miss.week?.[0]?.id
            ? lineChartData.target_miss.week?.[0]?.id
            : ''
          : null;
      const week =
        chartData !== undefined
          ? activeLevel === 'week'
            ? chartData.data.x.split('_')[1]
            : ''
          : null;
      // eslint-disable-next-line no-param-reassign
      queryParams = {
        decidingParameter,
        year,
        month: month ? moment().month(month).format('M') : '',
        week,
        ...queryParams,
      };

      setIsLoading(true);
      return axios
        .get(API_ENDPOINTS.DASHBOARD_LINE_CHART_DRILLDOWN, {
          params: queryParams,
        })
        .then(
          (response) => {
            setIsLoading(false);
            if (chartData !== undefined) {
              setLineChartData({
                target_miss: {
                  ...lineChartData.target_miss,
                  [nextActiveLevel]: response.data[nextActiveLevel],
                },
              });
              setLineChartConfig({
                ...lineChartConf,
                activeLevelIndex: nextActiveIndex,
              });
            } else {
              setLineChartData({
                target_miss: {
                  ...response?.data,
                },
              });
            }
          },
          () => {
            setIsLoading(false);
          }
        );
    }
  };
  /**
   * @function fetchUserHistory
   * @description A function to fetch user's history
   * @returns {Promise} returns an axios promise
   */
  const fetchUserHistory = (
    encryptedKey,
    baseEncryptedKey,
    screeningType,
    userLevel
  ) => {
    setIsLoading(true);
    const body = {
      search_id: encryptedKey,
      rank:
        userLevel === -1 ? (baseEncryptedKey === 'maker' ? 0 : 1) : userLevel,
      screen_type: screeningType === 'InitialScreening' ? 'initial' : 'ongoing',
    };
    return axios.post(API_ENDPOINTS.DASHBOARD_USER_HISTORY, body).then(
      (response) => {
        setIsLoading(false);
        setUserHistorySummary({
          details: response?.data,
          sanctionsList: response?.data?.result?.sanction_result,
          sanctionsCountryList: response?.data?.result?.sanction_country_result,
          pepList: response?.data?.result?.pep_result,
          amsList: response?.data?.result?.ams_result,
          legalList: response?.data?.result?.legal_result,
          corporateList: response?.data?.result?.corporate_result,
          riskList:
            response?.data?.result?.risk_rating_results?.[0]
              ?.risk_rating_result,
        });
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };

  // const availableSearch = () => {
  //   setIsLoading(true);

  //   return axios.get(API_ENDPOINTS.DASHBOARD_AVAILABLE_SEARCHES).then(
  //     (response) => {
  //       setIsLoading(false);
  //       setSearchData(response.data);
  //     },
  //     (e) => {
  //       setIsLoading(false);
  //       setIsError(e);
  //     }
  //   );
  // };
  /**
   * @function levelUpStackChart
   * @description A function for setting the stack chart configuration
   * @returns {void}
   */
  const levelUpStackChart = () => {
    if (stackChartConf.activeLevelIndex > 0 <= 3) {
      setStackChartConfig({
        ...stackChartConf,
        activeLevelIndex: stackChartConf.activeLevelIndex - 1,
      });
    }
  };
  /**
   * @function levelUpLineChart
   * @description A function for setting the line chart configuration
   * @returns {void}
   */
  const levelUpLineChart = () => {
    if (lineChartConf.activeLevelIndex > 0 <= 3) {
      setLineChartConfig({
        ...lineChartConf,
        activeLevelIndex: lineChartConf.activeLevelIndex - 1,
      });
    }
  };
  return {
    user,
    isError,
    isLoading,
    stats,
    fetchStats,
    userTypes,
    analystType,
    fetchStackChartDataForDrilldown,
    levelUpStackChart,
    stackChartConf,
    fetchLineChartDataForDrilldown,
    lineChartConf,
    levelUpLineChart,
    fetchUserHistory,
    userHistorySummary,
    fetchBatchInfo,
    // availableSearch,
    searchData,
    fetchHistory,
    reviewHistory,
    stackChartData,
    lineChartData,
    historyPageCount,
    fetchTeamData,
    teamList
  };
}
