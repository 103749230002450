// @ts-nocheck
import { useState, useCallback } from 'react';
import axios from 'axios';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import { Constants, Utils } from '@sams/common';
import { ExcelRenderer } from 'react-excel-renderer';
import { useSnackbar } from 'notistack';
import { API_ENDPOINTS } from '../const/ApiEndpoints';

const { PRIVATE_LIST_FILE_TYPE_COLUMNS } = Constants.CommonConst;

/**
 * @function useSanctionConfiguration
 * @description a custom hook which handles APIs related to sanction coniguration
 * @returns {object} object
 */
const useSanctionConfiguration = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [sanctionSource, setSanctionSource] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [privateList, setPrivateList] = useState([]);
  const [fileData, setfileData] = useState(null);
  const [listRowData, setListRowData] = useState({});
  const [totalPrivateLists, setTotalPrivateLists] = useState(0);
  const [privateListPageCount, setPrivateListPageCount] = useState(0);
  const [privateListFetched, setPrivateListFetched] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(5);
  const [pepData, setPepData] = useState([]);

  /**
   * @function fetchTreeData
   * @description A function to fetch tree data data
   * @returns {Promise} returns an axios promise
   */
  const fetchTreeData = (data) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.Sanction_Configuration_TREE_DATA, { params: data })
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setTreeData([...response?.data]);
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchPrivateList
   * @description A function to fetch private list data
   * @returns {Promise} returns an axios promise
   */
  const fetchPrivateList = useCallback((page = 0, pageSize = 5) => {
    setIsLoading(true);
    setCurrPage(page);
    setCurrPageSize(pageSize);
    return axios
      .get(API_ENDPOINTS.SANCTION_CONFIGURATION_PRIVATE_LIST, {
        params: {
          page_size: pageSize,
          page: page + 1,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setPrivateList([...response?.data]);
          setTotalPrivateLists(response.headers['x-total-count']);
          setPrivateListPageCount(
            Math.ceil(response.headers['x-total-count'] / pageSize)
          );
          setPrivateListFetched(!!response?.data?.length);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  }, []);
  /**
   * @function fetchPepData
   * @description A function to fetch pep list data
   * @returns {Promise} returns an axios promise
   */
  const fetchPepData = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.PEP_CONFIGURATION, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setPepData(response?.data);
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updatePepList
   * @description A function to update pep list data
   * @returns {Promise} returns an axios promise
   */
  const updatePepList = (data, userId) => {
    return axios
      .post(API_ENDPOINTS.PEP_CONFIGURATION, {
        data: data,
        ...(userId && { manager_id: userId }),
      })
      .then(() => {
        setIsLoading(false);
        setIsError();
      });
  };

  /**
   * @function deleteList
   * @description A function to delete list
   * @returns {Promise} returns an axios promise
   */
  const deleteList = (data) => {
    const params = {
      excel_status: true,
      list_id: data.list_id,
      key: data.key,
    };
    setIsLoading(true);
    return axios
      .delete(API_ENDPOINTS.SANCTION_CONFIGURATION_DELETE_LIST, {
        data: params,
      })
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('List deleted successfully', {
            variant: 'success',
          });
          fetchPrivateList(currPage, currPageSize);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchSanctionSource
   * @description A function to fetch Sanction Source data
   * @returns {Promise} returns an axios promise
   */
  const fetchSanctionSource = (data) => {
    axios
      .get(API_ENDPOINTS.Sanction_Configuration_SANCTION_SOURCE, {
        params: data,
      })
      .then((response) => setSanctionSource(response.data));
  };
  const updateSanctionSource = (data) => {
    const body = {
      sanctions_list: data?.sanctionList,
    };
    const queryParams = {
      manager_id: data?.manager_id,
    };
    return axios
      .post(API_ENDPOINTS.Sanction_Configuration_SANCTION_SOURCE, body, {
        params: queryParams,
      })
      .then(
        (response) => {
          if (response) {
            enqueueSnackbar(response?.data?.Msg, {
              variant: 'success',
            });
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function importFile
   * @description A function to upload file
   * @returns {Promise} returns an axios promise
   */
  const importFile = (payload) => {
    return axios
      .post(API_ENDPOINTS.SANCTION_CONFIGURATION_PRIVATE_LIST_PROCESS, payload)
      .then(
        (res) => {
          setTimeout(() => {
            setfileData(null);
          }, 100);
          enqueueSnackbar(res?.data?.Msg, {
            variant: 'success',
          });
          setPrivateListFetched(false);
          setTimeout(() => {
            setPrivateListFetched(true);
          }, 100);
          return res;
        },
        () => {
          enqueueSnackbar(
            'Please upload excel file in correct format, you can also refer sample file below',
            {
              variant: 'warning',
            }
          );
        }
      );
  };
  /**
   * @function initiateFileUpload
   * @description A function to upload files
   * @returns {Promise} returns an axios promise
   */
  // MULTI PART UPLOAD
  const initiateFileUpload = (fileDetails, successCallback, errorCallback) => {
    axios.post(API_ENDPOINTS.MULTIPART_UPLOAD_START_UPLOAD, fileDetails).then(
      (resp) => {
        if (successCallback) {
          successCallback(resp);
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
  /**
   * @function fetchPresignedUrlForEachChunk
   * @description A function to post url
   * @returns {Promise} returns an axios promise
   */
  const fetchPresignedUrlForEachChunk = (chunkDetails) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_GET_PRESIGNED_URL, chunkDetails)
      .then();

  /**
   * @function onCompleteMultipartUpload
   * @description A function to upload files in multiple parts
   * @returns {Promise} returns an axios promise
   */
  const onCompleteMultipartUpload = (
    fileChunksSummary,
    successCallback,
    errorCallback
  ) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_COMPLETE, {
        ...fileChunksSummary,
      })
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  const { startUpload, progress } = useMultipartFileUploadToS3({
    startUploadCallback: initiateFileUpload,
    fetchPresignedUrlForEachChunk,
    onCompleteMultipartUpload,
  });
  /**
   * @function verifyPrivateListFile
   * @description A function to verify Private List File
   * @returns {Promise} returns an axios promise
   */
  const verifyPrivateListFile = (file, listType) => {
    return ExcelRenderer(file).then(
      (resp) => {
        const cols = resp.rows[0];
        if (file?.type === 'application/vnd.oasis.opendocument.spreadsheet') {
          return;
        }
        return Utils.CoreUtils.validateFileColumns(
          cols,
          PRIVATE_LIST_FILE_TYPE_COLUMNS[listType]
        );
      },
      (err) => {
        enqueueSnackbar('Error while processing the file', {
          variant: 'error',
        });
        throw err;
      }
    );
  };
  const commonRuleSourceUpdate = (data) => {
    const body = {
      sanction_list: data,
    };
    axios
      .post(API_ENDPOINTS.SANCTION_CONFIGURATION_COMMON_RULE, body)
      .then((e) => {
        setIsLoading(false);
        setIsError(e);
      });
  };
  return {
    isError,
    isLoading,
    treeData,
    sanctionSource,
    setTreeData,
    fetchSanctionSource,
    updateSanctionSource,
    fileData,
    setfileData,
    importFile,
    privateList,
    listRowData,
    setListRowData,
    deleteList,
    startUpload,
    progress,
    verifyPrivateListFile,
    fetchPrivateList,
    privateListPageCount,
    totalPrivateLists,
    privateListFetched,
    setPrivateListFetched,
    commonRuleSourceUpdate,
    fetchPepData,
    pepData,
    updatePepList,
    fetchTreeData,
  };
};
export default useSanctionConfiguration;
