import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from 'const/ApiEndpoints';

export default function useCommon() {
  const [analystList, setAnalystList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [auditTrailPageCount, setAuditTrailPageCount] = useState(0);
  const [auditTrailHistory, setAuditTrailHistory] = useState([]);
  const [isError, setIsError] = useState(false);

  const fetchTeamData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.INITIATE_SCREEN_TEAM_DATA).then(
      (response) => {
        setIsLoading(false);
        setTeamList(response?.data);
      },
      (e) => {
        setIsLoading(false);
      }
    );
  };
  const fetchAnalystData = () => {
    setAnalystList([]);
    return axios.get(API_ENDPOINTS.DASHBOARD_USER_TYPE).then(
      (response) => {
        if (response.length !== 0) {
          setAnalystList(response.data);
        }
      },
      () => {}
    );
  };
  /**
   * @function fetchAuditTrailHistory
   * @description A function to fetch user's audit trail history
   * @returns {Promise} returns an axios promise
   */

  const fetchAuditTrailHistory = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.AUDIT_TRAIL_HISTORY, {
        params: queryParams,
      })
      .then(
        (response) => {
          setAuditTrailPageCount(response?.headers['x-total-count']);
          setIsLoading(false);
          if (response?.data) {
            setAuditTrailHistory(response?.data);
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };

  return {
    fetchTeamData,
    fetchAnalystData,
    analystList,
    teamList,
    isLoading,
    isError,
    fetchAuditTrailHistory,
    auditTrailHistory,
    auditTrailPageCount
  };
}
