/* eslint-disable camelcase */
// @ts-nocheck
import Sample_ind from './DocumentSample/bulkFileUpload/Individual_sample.xlsx';
import Sample_initial_ind from './DocumentSample/bulkFileUpload/Individual_initial_sample.xlsx';
import Sample_entity from './DocumentSample/bulkFileUpload/Entity_sample.xlsx';
import Sample_initial_entity from './DocumentSample/bulkFileUpload/Entity_initial_sample.xlsx';
import Sample_risk_ind from './DocumentSample/bulkFileUpload/Individual_risk_sample.xlsx';
import Sample_risk_entity from './DocumentSample/bulkFileUpload/Entity_risk_sample.xlsx';
import Sample_ongoing_risk_ind from './DocumentSample/bulkFileUpload/Individual_ongoing_risk_sample.xlsx';
import Sample_ongoing_risk_entity from './DocumentSample/bulkFileUpload/Entity_ongoing_risk_sample.xlsx';
import Sample_ongoing_transaction_ind from './DocumentSample/bulkFileUpload/Individual_ongoing_transaction_sample.xlsx';
import Sample_ongoing_transaction_entity from './DocumentSample/bulkFileUpload/Entity_ongoing_transaction_sample.xlsx';
import Sample_ongoing_transaction_both from './DocumentSample/bulkFileUpload/Both_ongoing_transaction_sample.xlsx';
import Sample_initial_transaction_ind from './DocumentSample/bulkFileUpload/Individual_initial_transaction_sample.xlsx';
import Sample_initial_transaction_entity from './DocumentSample/bulkFileUpload/Entity_initial_transaction_sample.xlsx';
import Sample_initial_transaction_both from './DocumentSample/bulkFileUpload/Both_initial_transaction_sample.xlsx';
import Sample_vessel from './DocumentSample/bulkFileUpload/Vessel_sample.xlsx';
import Sample_initial_vessel from './DocumentSample/bulkFileUpload/Vessel_initial_sample.xlsx';
import Sample_aircraft from './DocumentSample/bulkFileUpload/Aircraft_sample.xlsx';
import Sample_initial_aircraft from './DocumentSample/bulkFileUpload/Aircraft_initial_sample.xlsx';
import Sample_Right_Share_TTRReport from './DocumentSample/TTR_right_share_report.xlsx';
import Sample_TTRReport from './DocumentSample/TTR_report.xlsx';
import Sample_Capital_Market_TTRReport from './DocumentSample/TTR_capital_market_report.xlsx';
import Sample_ind_privateFile from './DocumentSample/importPrivateListUpload/Individual_Sample.xlsx';
import Sample_entity_privateFile from './DocumentSample/importPrivateListUpload/Entity_Sample.xlsx';
import Sample_vessel_privateFile from './DocumentSample/importPrivateListUpload/Vessel_Sample.xlsx';
import Sample_aircraft_privateFile from './DocumentSample/importPrivateListUpload/Aircraft_Sample.xlsx';
import Sample_Ams_NameFile from './DocumentSample/AmsCrawlerSampleFile/AMS_Name_Sample.xlsx';
import Sample_Ams_KeywordFile from './DocumentSample/AmsCrawlerSampleFile/AMS_Keyword_Sample.xlsx';
import Sample_OccupationFile from './DocumentSample/RiskRatingBulkSample/occupation_file.xlsx';
import Sample_ProductFile from './DocumentSample/RiskRatingBulkSample/product_file.xlsx';
import Sample_IndustryFile from './DocumentSample/RiskRatingBulkSample/industry_file.xlsx';
import Sample_TransactionType from './DocumentSample/TransactionScreeningSample/transaction_type_sample.xlsx';
import Sample_TransactionProductType from './DocumentSample/TransactionScreeningSample/transaction_product_type_sample.xlsx';
import Sample_ChannelType from './DocumentSample/TransactionScreeningSample/channel_type_sample.xlsx';

// sideIcons blue
import download_Icon from './iconDesign/blue/download.svg';
import ttr_Icon from './iconDesign/blue/ttr.svg';
import dashboard_Icon from './iconDesign/blue/interface.svg';
import ruleEngine_icon from './iconDesign/blue/rule-engine.svg';
import initiate_icon from './iconDesign/blue/initiate.svg';
import initial_icon from './iconDesign/blue/computer.svg';
import ongoing_icon from './iconDesign/blue/financing.svg';
import sanction_configuration_icon from './iconDesign/blue/sanction-configuration.svg';
import access_management_icon from './iconDesign/blue/accessManagement.svg';
import costing_icon from './iconDesign/blue/costing.svg';
import referral_icon from './iconDesign/blue/referral.svg';
import risk_rating_dashboard_icon from './iconDesign/blue/riskRatingDashboard.svg';
import history_blue_icon from './iconDesign/blue/history.svg';
// second color green
import download_Icon_green from './iconDesign/green/download.svg';
import ttr_Icon_green from './iconDesign/green/ttr.svg';
import dashboard_Icon_green from './iconDesign/green/interface.svg';
import ruleEngine_icon_green from './iconDesign/green/rule-engine.svg';
import initiate_icon_green from './iconDesign/green/initiate.svg';
import initial_icon_green from './iconDesign/green/computer.svg';
import ongoing_icon_green from './iconDesign/green/financing.svg';
import sanction_configuration_icon_green from './iconDesign/green/sanction-configuration.svg';
import access_management_icon_green from './iconDesign/green/accessManagement.svg';
import costing_icon_green from './iconDesign/green/costing.svg';
import referral_icon_green from './iconDesign/green/referral.svg';
import risk_rating_dashboard_icon_green from './iconDesign/green/riskRatingDashboard.svg';
import history_green_icon from './iconDesign/green/history.svg';

import dashboard_history from './images/dashboard_history.png';
import bggif from './images/GIF_SAMS.gif';
import loginBackground from './images/loginBackground.svg';
import Banklogo from './images/logo.png';
import solyticsLogo from './images/logo2.png';
import percentage from './images/percentage.svg';
import profileCard from './images/profile-card.svg';
import profileRejected from './images/profile-rejected.svg';
import SAMS_logo from './images/SAMS_logo.svg';
import SAMSLogo from './images/SAMSLogo.svg';
import shipment from './images/shipment.svg';
import upload from './images/upload.svg';
import user from './images/user.svg';
import userIcon from './images/userIcon.png';
import loader from './images/loader.svg';
import solyticsPartnersLogo from './images/SolyticsPartnersLogoWhite.svg';
import accepted from './images/rr_dashboard_accepted.svg';
import available_search from './images/rr_dashboard_available_search.svg';
import initial from './images/rr_dashboard_initial.svg';
import ongoing from './images/rr_dashboard_ongoing.svg';
import rejected from './images/rr_dashboard_rejected.svg';
import total from './images/rr_dashboard_total.svg';
import pending from './images/rr_dashboard_pending.svg';

export const Assets = {
  Sample_ind,
  Sample_entity,
  Sample_risk_ind,
  Sample_risk_entity,
  Sample_vessel,
  Sample_aircraft,
  Sample_ind_privateFile,
  Sample_entity_privateFile,
  Sample_vessel_privateFile,
  Sample_aircraft_privateFile,
  Sample_TTRReport,
  Sample_Capital_Market_TTRReport,
  access_management_icon_green,
  costing_icon_green,
  initial_icon_green,
  ongoing_icon_green,
  dashboard_Icon_green,
  initiate_icon_green,
  ruleEngine_icon_green,
  sanction_configuration_icon_green,
  download_Icon_green,
  ttr_Icon_green,
  ttr_Icon,
  referral_icon_green,
  access_management_icon,
  costing_icon,
  initial_icon,
  ongoing_icon,
  dashboard_Icon,
  initiate_icon,
  ruleEngine_icon,
  sanction_configuration_icon,
  download_Icon,
  referral_icon,
  dashboard_history,
  bggif,
  loginBackground,
  Banklogo,
  solyticsLogo,
  percentage,
  profileCard,
  profileRejected,
  SAMS_logo,
  SAMSLogo,
  shipment,
  upload,
  user,
  userIcon,
  loader,
  solyticsPartnersLogo,
  Sample_Ams_NameFile,
  Sample_Ams_KeywordFile,
  Sample_initial_ind,
  Sample_initial_entity,
  Sample_initial_vessel,
  Sample_initial_aircraft,
  Sample_ongoing_risk_ind,
  Sample_ongoing_risk_entity,
  Sample_OccupationFile,
  Sample_IndustryFile,
  Sample_ProductFile,
  risk_rating_dashboard_icon,
  risk_rating_dashboard_icon_green,
  history_blue_icon,
  history_green_icon,
  pending,
  total,
  rejected,
  ongoing,
  initial,
  available_search,
  accepted,
  Sample_TransactionType,
  Sample_ChannelType,
  Sample_TransactionProductType,
  Sample_ongoing_transaction_both,
  Sample_ongoing_transaction_entity,
  Sample_ongoing_transaction_ind,
  Sample_initial_transaction_both,
  Sample_initial_transaction_entity,
  Sample_initial_transaction_ind,
  Sample_Right_Share_TTRReport,
};
