import React from 'react';
import ReactDOM from 'react-dom';
import { AlertDialogModal } from '@sams/common';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

const UserConfirmationPrompt = (theme) => {
  const userConfirmationPrompt = React.useCallback((message, callback) => {
    const node = document.getElementById('custom-prompt');

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
      callback(answer);
    };
    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);

    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AlertDialogModal
            open={true}
            handleClose={cancel}
            handleTask={ok}
            ContentText={message}
            ButtonName="yes"
          />
        </ThemeProvider>
      </StyledEngineProvider>,
      node
    );
  }, []);
  return {
    userConfirmationPrompt,
  };
};

export default UserConfirmationPrompt;
