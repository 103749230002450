import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';

/**
 * @function useAccessManagement
 * @description a custom hook which handles APIs related to Access Management page
 * @returns {object} object
 */
const useAccessManagement = () => {
  const { user } = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();
  const [accessData, setAccessData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [analystList, setAnalystList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [groupData, setGroupData] = useState({});
  const [teamRowData, setTeamRowData] = useState({});
  const [userRowData, setUserRowData] = useState({});
  const [screen, setScreen] = useState('accessManagement');
  const [isError, setIsError] = useState(false);

  /**
   * @function fetchAnalystData
   * @description A function to fetch analyst data
   * @returns {Promise} returns an axios promise
   */
  const fetchAnalystData = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.ACCESS_MANAGEMENT_USER, {
        params: queryParams || 0,
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setAnalystList(response?.data);
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchTeamData
   * @description A function to fetch team data
   * @returns {Promise} returns an axios promise
   */
  const fetchTeamData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ACCESS_MANAGEMENT_TEAM).then(
      (response) => {
        setIsLoading(false);
        setTeamList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchUserData
   * @description A function to fetch user's data
   * @returns {Promise} returns an axios promise
   */
  const fetchUserData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ACCESS_MANAGEMENT_USER).then(
      (response) => {
        setIsLoading(false);
        setUserList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchUserData
   * @description A function to fetch group list
   * @returns {Promise} returns an axios promise
   */
  const fetchGroupData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ACCESS_MANAGEMENT_GROUP).then(
      (response) => {
        setIsLoading(false);
        setGroupList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchPermissions
   * @description A function to fetch permissions list
   * @returns {Promise} returns an axios promise
   */
  const fetchPermissions = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ACCESS_MANAGEMENT_PERMISSION).then(
      (response) => {
        setIsLoading(false);
        setPermissionsList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  useEffect(() => {
    fetchUserData();
    fetchTeamData();
    fetchGroupData();
    fetchPermissions();
  }, [screen]);
  /**
   * @function fetchGroup
   * @description A function to fetch group data
   * @returns {Promise} returns an axios promise
   */
  const fetchGroup = (data) => {
    setIsLoading(true);
    return axios.get(`${API_ENDPOINTS.ACCESS_MANAGEMENT_GROUP}${data}/`).then(
      (response) => {
        setIsLoading(false);
        setGroupData(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function createNewGroup
   * @description A function to post data for created group
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const createNewGroup = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.ACCESS_MANAGEMENT_GROUP, data).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Group created Successfully', {
          variant: 'success',
        });
        setScreen('accessManagement');
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateGroup
   * @description A function to update the group
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const updateGroup = (data) => {
    setIsLoading(true);
    return axios
      .patch(`${API_ENDPOINTS.ACCESS_MANAGEMENT_GROUP}${data?.id}/`, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Group updated Successfully', {
            variant: 'success',
          });
          setScreen('accessManagement');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteGroup
   * @description A function to delete group data'
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const deleteGroup = (data) => {
    const params = {
      id: data.id,
    };
    setIsLoading(true);
    return axios
      .delete(`${API_ENDPOINTS.ACCESS_MANAGEMENT_GROUP}${params?.id}/`)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Group deleted successfully', {
            variant: 'success',
          });
          fetchGroupData();
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function createNewTeam
   * @description A function to post data for created team
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const createNewTeam = (data) => {
    const analysts = [];
    if (data.maker) analysts.push(data.maker);
    if (data.checker1) analysts.push(data.checker1);
    if (data.checker2) analysts.push(data.checker2);
    if (data.checker3) analysts.push(data.checker3);
    if (data.checker4) analysts.push(data.checker4);
    if (data.checker5) analysts.push(data.checker5);
    const body = {
      name: data?.name,
      branch: data?.branch,
      product: data?.product,
      analysts,
      description: data?.description,
    };
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.ACCESS_MANAGEMENT_TEAM, body).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Team created Successfully', {
          variant: 'success',
        });
        setScreen('accessManagement');
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  const createHorizontalTeam = (data) => {
    const body = { ...data };
    return axios
      .post(API_ENDPOINTS.ACCESS_MANAGEMENT_HORIZONTAL_TEAM, body)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Team created Successfully', {
            variant: 'success',
          });
          setScreen('accessManagement');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  const updateHorizontalTeam = (data) => {
    const body = { ...data };
    return axios
      .put(API_ENDPOINTS.ACCESS_MANAGEMENT_HORIZONTAL_TEAM, body)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Team updated Successfully', {
            variant: 'success',
          });
          setScreen('accessManagement');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateTeam
   * @description A function to update the team
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const updateTeam = (data) => {
    const analysts = [];
    if (data.maker) analysts.push(Number(data.maker));
    if (data.checker1) analysts.push(Number(data.checker1));
    if (data.checker2) analysts.push(Number(data.checker2));
    if (data.checker3) analysts.push(Number(data.checker3));
    if (data.checker4) analysts.push(Number(data.checker4));
    if (data.checker5) analysts.push(Number(data.checker5));
    const body = {
      id: data.id,
      name: data?.name,
      branch: data?.branch,
      product: data?.product,
      analysts,
      description: data?.description,
    };
    setIsLoading(true);
    return axios
      .patch(`${API_ENDPOINTS.ACCESS_MANAGEMENT_TEAM}${data?.id}/`, body)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Team updated Successfully', {
            variant: 'success',
          });
          setScreen('accessManagement');
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteTeam
   * @description A function to delete the team data
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const deleteTeam = (data) => {
    setIsLoading(true);
    return axios
      .delete(`${API_ENDPOINTS.ACCESS_MANAGEMENT_TEAM}${data?.id}/`)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Team deleted successfully', {
            variant: 'success',
          });
          fetchTeamData();
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function createNewUser
   * @description A function to create new users
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const createNewUser = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.ACCESS_MANAGEMENT_USER, data).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('User registered successfully', {
          variant: 'success',
        });
        setScreen('accessManagement');
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  const createNewUserAdmin = (data) => {
    setIsLoading(true);
    return axios
      .post(`${API_ENDPOINTS.ACCESS_MANAGEMENT_USER_ADMIN}`, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Manager registered successfully', {
            variant: 'success',
          });
          setScreen('admin');
          fetchUserData(dbName);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateUser
   * @description A function to upadate user's data'
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const updateUser = (data) => {
    setIsLoading(true);
    return axios
      .patch(`${API_ENDPOINTS.ACCESS_MANAGEMENT_USER}${data?.id}/`, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('User updated successfully', {
            variant: 'success',
          });
          setScreen('accessManagement');
        },
        (e) => {
          enqueueSnackbar(e?.response?.data?.Msg, {
            variant: 'error',
          });
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteUser
   * @description A function to delete user's data'
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const deleteUser = (data) => {
    setIsLoading(true);
    return axios
      .delete(`${API_ENDPOINTS.ACCESS_MANAGEMENT_USER}${data?.id}/`)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('User deleted successfully', {
            variant: 'success',
          });
          fetchUserData();
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };

  return {
    user,
    isError,
    screen,
    setScreen,
    accessData,
    setAccessData,
    isLoading,
    setIsLoading,
    teamList,
    userList,
    groupList,
    analystList,
    teamRowData,
    setTeamRowData,
    userRowData,
    setUserRowData,
    createNewTeam,
    updateTeam,
    deleteTeam,
    createNewUser,
    updateUser,
    deleteUser,
    fetchAnalystData,
    fetchGroup,
    groupData,
    createNewGroup,
    updateGroup,
    deleteGroup,
    permissionsList,
    createNewUserAdmin,
    createHorizontalTeam,
    updateHorizontalTeam,
  };
};

export default useAccessManagement;
