// @ts-nocheck
import { createTheme } from '@mui/material/styles';
import _ from 'lodash';
import baseTheme from './base';

export default createTheme(
  _.merge(baseTheme, {
    themeName: 'SAMS Dark',
  })
);
