// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  List,
  Box,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Avatar,
} from '@mui/material';
import { ExpandLess, ExpandMore, FiberManualRecord } from '@mui/icons-material';
import { ICON_BLUE, ICON_GREEN } from '../const/SidebarMenus';

const SingleLevel = ({ key, item, history, classes, subMenu, isActiveSub }) => {
  const { pathname } = useLocation();

  return (
    <ListItemButton
      key={key}
      className={
        pathname === item.route
          ? subMenu
            ? classes?.subMenu
            : classes?.menuSelected
          : classes?.menuNormal
      }
      sx={{
        py: 1,
        mt: subMenu ? 2 : 3.5,
      }}
      onClick={() => history.push(item.route)}
    >
      {subMenu ? (
        <ListItemIcon sx={{ minWidth: 25 }}>
          <FiberManualRecord
            sx={{
              width: 0.5,
            }}
            fontSize="small"
            color={isActiveSub ? 'primary' : 'inherit'}
          />
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <Avatar
            variant="square"
            src={
              pathname === item.route
                ? ICON_GREEN[item.label]
                : ICON_BLUE[item.label]
            }
            sx={{ width: 30, height: 30 }}
          />
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ fontWeight: 600 }}
        disableTypography
        primary={item.label}
      />
    </ListItemButton>
  );
};

const MultiLevel = ({ key, item, history, classes }) => {
  const { pathname } = useLocation();
  const isActivePath =
    `/${pathname.split('/')?.[1]}` === item?.route || pathname === item?.route;

  const [open, setOpen] = useState(isActivePath);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        key={key}
        className={
          `/${pathname.split('/')?.[1]}` === item?.route
            ? classes?.menuSelected
            : classes?.menuNormal
        }
        sx={{ pt: 1, pb: 1, mt: 3.5 }}
        onClick={() => {
          handleClick();
        }}
      >
        <ListItemIcon>
          <Avatar
            variant="square"
            src={
              `/${pathname.split('/')?.[1]}` === item?.route
                ? ICON_GREEN[item.label]
                : ICON_BLUE[item.label]
            }
            sx={{ width: 30, height: 30 }}
          />
        </ListItemIcon>
        <ListItemText>
          <Typography
            variant="subtitle2"
            color={pathname === item.route ? 'primary' : 'inherit'}
          >
            <Box fontWeight={600} fontFamily="Montserrat">
              {item.label}
            </Box>
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ pl: 4 }} component="div" disablePadding>
          {Object.values(item?.children)?.map((child, index) => {
            const isActiveSub = child.route === pathname;
            return (
              <MenuItem
                key={index.route}
                item={child}
                history={history}
                classes={classes}
                isActiveSub={isActiveSub}
                subMenu={true}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};
const MenuItem = React.memo(
  ({ key, item, history, classes, isActiveSub, subMenu }) => {
    const MenuComponent = item.is_parent ? MultiLevel : SingleLevel;
    return (
      <MenuComponent
        key={key}
        item={item}
        history={history}
        classes={classes}
        subMenu={subMenu}
        isActiveSub={isActiveSub}
      />
    );
  }
);

export default function SidebarMenu({ panelNavigation, history, classes }) {
  return Object?.values(panelNavigation)
    ?.filter((data) => data.permission)
    ?.map((item, index) => (
      <MenuItem
        key={index.id}
        item={item}
        history={history}
        classes={classes}
      />
    ));
}

SingleLevel.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  key: PropTypes.number.isRequired,
  isActiveSub: PropTypes.bool.isRequired,
  subMenu: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
MultiLevel.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  key: PropTypes.number.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
MenuItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  key: PropTypes.number.isRequired,
  isActiveSub: PropTypes.bool.isRequired,
  subMenu: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
