import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';

/**
 * @function useAssignSearches
 * @description a custom hook which handles APIs related to Assign Searches
 * @returns {object} object
 */
const useAssignSearches = () => {
  const { user } = useSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initiatedData, setInitiatedData] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [stepsWiseFormData, setStepWiseFormData] = useState({});

  /**
   * @function fetchTeamData
   * @description A function to fetch team data
   * @returns {Promise} returns an axios promise
   */
  const fetchTeamData = (queryParams) => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.INITIATE_SCREEN_TEAM_DATA,{
      params: queryParams
    }).then(
      (response) => {
        setIsLoading(false);
        setTeamList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  useEffect(() => {
    fetchTeamData({
      assign_search: true
    });
  }, []);
  /**
   * @function fetchSearchData
   * @description A function to fetch searched information
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const fetchSearchData = (data) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.INITIATE_SCREEN_MANUAL_ASSIGN, {
        params: {
          ...data,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setInitiatedData(response?.data);
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };

  const assignSearches = (data) => {
    // eslint-disable-next-line no-shadow
    const { selectData, values, selectedTeams } = data;
    // eslint-disable-next-line no-shadow
    const searchId = selectData?.map((data) => data.original.search_id);
    // eslint-disable-next-line no-shadow
    const batchId = selectData?.map((data) =>
      data.original.batch_id ? data.original.batch_id : []
    );
    const body = {
      searchFor: values.searchFor,
      assignType: values.assignType,
      screeningType: values.screeningType,
      searchType: values.searchType,
      team: values.team,
      alerts: values.alerts,
      batchId: values.batchId,
      startDate: values.startDate,
      endDate: values.endDate,
      selectedTeams,
      selectedSearches: batchId[0]?.length === 0 ? searchId : batchId,
    };
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.INITIATE_SCREEN_MANUAL_ASSIGN, body).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Assign searches successfully', {
          variant: 'success',
        });
        setTimeout(() => {
          fetchSearchData(values);
        }, 800);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  
  const manualAssignSearches = (data) => {
    const { selectData, values, selectedTeams, analyst_id, manager_id } = data;
    const searchId = selectData?.map((data) => data.original.search_id);
    const batchId = selectData?.map((data) =>
      data.original.batch_id ? data.original.batch_id : []
    );
    const body = {
      screening_type: values.screeningType?.[0],
      search_id: batchId[0]?.length === 0 ? searchId : batchId,
      target_user_id: analyst_id,
      manager_id
    }; 
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.INITIATE_SCREEN_MANUAL_ASSIGN_SEARCHES, body).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Assign searches successfully', {
          variant: 'success',
        });
        setTimeout(() => {
          fetchSearchData(values);
        }, 800);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  return {
    user,
    isError,
    isLoading,
    fetchSearchData,
    teamList,
    initiatedData,
    selectData,
    setSelectData,
    setInitiatedData,
    assignSearches,
    manualAssignSearches,
    stepsWiseFormData,
    setStepWiseFormData,
  };
};

export default useAssignSearches;
