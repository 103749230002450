// @ts-nocheck
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';
import useDashBoard from './useDashboard';

/**
 * @function useDownloads
 * @description a custom hook which handles APIs related to download status and report screen
 * @returns {object} object
 */
export default function useDownloads() {
  const { user } = useSelector(selectAuth);
  const [downloads, setDownloads] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState([]);
  const [excelId, setExcelId] = useState([]);
  const [statusUrl, setStatusUrl] = useState({});
  const [reportUrls, setReportUrls] = useState({});
  const [excelReportId, setExcelReportId] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [filters, setFilters] = useState(null);
  const [tableColumns, setTableColumns] = useState({});
  const [newTableColumns, setNewTableColumns] = useState({});
  const [channel, setChannel] = useState({});
  const [transactionType, setTransactionType] = useState({});
  const [productType, setProductType] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { userTypes, analystType } = useDashBoard();
  /**
   * @function fetchDownloads
   * @description A function to fetch download report data
   * @returns {Promise} returns an axios promise
   */
  const fetchDownloads = (data) => {
    const params = { ...data };
    if (data?.alerted?.length === 1) {
      if (data?.alerted?.includes('Alert')) {
        params.alerted = true;
      } else {
        params.alerted = false;
      }
    } else {
      params.alerted = 'all';
    }
    if (data.status?.length === 1) {
      switch (data?.status[0]) {
        case 'Pending':
          params.status = 'q';
          break;
        case 'Accepted':
          params.status = 'a';
          break;
        case 'Rejected':
          params.status = 'r';
          break;
        default:
          params.status = 'r';
      }
    } else {
      params.status = 'ar';
    }
    if (!data?.risk_type?.length) {
      params.risk_rating = false;
      params.risk_type = 'none';
    } else if (data?.risk_type?.length === 3) {
      params.risk_rating = true;
      params.risk_type = 'all';
    } else {
      params.risk_rating = true;
      params.risk_type = data?.risk_type?.join(',').toLowerCase();
    }
    if (data.team === '' && data.baseStr === '' && data.analyst_name === '') {
      delete params.team;
      delete params.baseStr;
      delete params.analyst_name;
    }
    // if(data?.risk_type?.le)
    const LIST_TYPE = {
      Sanctions: 'sanction',
      PEP: 'PEP',
      AMS: 'AMS',
      Corporate: 'corporate',
      Legal: 'legal',
    };
    if (data.listType && Array.isArray(data.listType)) {
      Object.keys(LIST_TYPE).forEach((key) => {
        params[LIST_TYPE[key]] = data.listType.includes(key);
      });
      delete params.listType;
    }
    params.start_date = moment(data.dates.Start_Date).format('YYYY-MM-DD');
    params.end_date = moment(data.dates.End_Date).format('YYYY-MM-DD');
    delete params.dates;
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.DOWNLOAD_REPORT, { params }).then(
      (response) => {
        setIsLoading(false);
        if (response.data) {
          setExcelReportId(response.data?.id);
          setDownloads(response.data.data);
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchDownloadReportLink
   * @description A function to fetch download reports link data
   * @returns {Promise} returns an axios promise
   */
  const fetchDownloadReportLink = () => {
    const params = excelReportId;
    return axios.get(`${API_ENDPOINTS.DOWNLOAD_REPORT}${params}`).then(
      (response) => {
        if (response?.status === 200) {
          if (response.data?.Msg) {
            enqueueSnackbar(response.data?.Msg, {
              variant: 'success',
            });
          } else {
            setReportUrls(response.data);
          }
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };
  /**
   * @function customizeFilterAndColumns
   * @description A function to customize filters and table columns
   * @returns {Promise} returns an axios promise
   */
  const customizeFilterAndColumns = (data) => {
    const params = { ...data };
    return axios
      .get(API_ENDPOINTS.CUSTOMIZE_FILTERS_AND_COLUMNS, {
        params,
      })
      .then(
        (response) => {
          if (response?.status === 200) {
            setIsLoading(true);
            setFilters(response?.data?.filter_criteria);
            setTableColumns(response?.data?.selected_column);
            setNewTableColumns(response?.data?.selected_column);
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  /**
   * @function addCustomizeFilterAndColumns
   * @description A function to add customize filters and table columns
   * @returns {Promise} returns an axios promise
   */
  const addCustomizeFilterAndColumns = (data) => {
    const body = { ...data };
    return axios.post(API_ENDPOINTS.CUSTOMIZE_FILTERS_AND_COLUMNS, body).then(
      (response) => {
        enqueueSnackbar(response.data?.message, {
          variant: 'success',
        });
        customizeFilterAndColumns({
          selected_column: true,
          filters: true,
          feature_name: data?.feature_name,
        });
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };
  /**
   * @function getTransactionParameters
   * @description A function to get transaction parameters
   * @returns {Promise} returns an axios promise
   */
  const getTransactionParameters = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.TRANSACTION_SCREENING_RULE_ENGINE, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          response?.data?.data?.map((item) => {
            if (item?.name === 'channel_type') {
              setChannel({ values: item?.values, mandatory: item?.mandatory });
            }
            if (item?.name === 'transaction_type') {
              setTransactionType({
                values: item?.values,
                mandatory: item?.mandatory,
              });
            }
            if (item?.name === 'transaction_product_type') {
              setProductType({
                values: item?.values,
                mandatory: item?.mandatory,
              });
            }
          });
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  /**
   * @function fetchDownloadStatus
   * @description A function to fetch download status data
   * @returns {Promise} returns an axios promise
   */
  const fetchDownloadStatus = (data) => {
    const params = { ...data };
    params.start_date = moment(data.dates.start_date).format('YYYY-MM-DD');
    params.end_date = moment(data.dates.end_date).format('YYYY-MM-DD');
    params.due_date = moment(data.due_date).format('YYYY-MM-DD');
    delete params.dates;
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.DOWNLOAD_STATUS, {
        params,
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (response.data) {
            setExcelId(response.data?.id);
            setDownloadStatus(response.data.data);
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchDownloadStatusLink
   * @description A function to fetch download status link data
   * @returns {Promise} returns an axios promise
   */
  const fetchDownloadStatusLink = () => {
    const params = excelId;
    setIsLoading(true);
    return axios.get(`${API_ENDPOINTS.DOWNLOAD_STATUS_EXCEL}${params}`).then(
      (response) => {
        if (response?.status === 200) {
          setIsLoading(false);
          if (response.data?.Msg) {
            enqueueSnackbar(response.data?.Msg, {
              variant: 'success',
            });
          } else {
            setStatusUrl(response.data);
          }
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };
  /**
   * @function fetchTeamData
   * @description A function to fetch team data
   * @returns {Promise} returns an axios promise
   */
  const fetchTeamData = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.INITIATE_SCREEN_TEAM_DATA, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setTeamList(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  useEffect(() => {
    fetchTeamData();
  }, []);
  return {
    isError,
    isLoading,
    user,
    downloads,
    setDownloads,
    fetchDownloads,
    teamList,
    downloadStatus,
    fetchDownloadStatus,
    setDownloadStatus,
    fetchDownloadStatusLink,
    statusUrl,
    fetchDownloadReportLink,
    reportUrls,
    excelReportId,
    excelId,
    setExcelId,
    setStatusUrl,
    setReportUrls,
    userTypes,
    analystType,
    fetchTeamData,
    filters,
    setFilters,
    tableColumns,
    customizeFilterAndColumns,
    addCustomizeFilterAndColumns,
    getTransactionParameters,
    channel,
    transactionType,
    productType,
    newTableColumns,
  };
}
