// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../Common/CustomTextField';
import { Constants } from '../../const/index';

const { Regex } = Constants;

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required!'),
  newPassword: Yup.string()
    .required('Please Enter your password')
    .matches(
      Regex.PASSWORD,
      'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'
    ),
  conformPassword: Yup.string()
    .required('Required!')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
});
/**
@function ChangePasswordForm
@description - A form to change the current password
@param {boolean} passwordFrom - Boolean value to open the form
@param {function} handlePasswordClose - function to close the form
@param {function} changePassword - function to patch the changed value
@returns {JSX} ChangePasswordForm UI
@example <ChangePasswordForm  />
 */
const ChangePasswordForm = ({
  passwordFrom,
  handlePasswordClose,
  changePassword,
  logout,
}) => {
  const passwordForm = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      conformPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      changePassword(values).then(() => {
        setTimeout(() => {
          logout();
        }, 800);
      });
      handlePasswordClose();
      passwordForm.resetForm();
    },
  });
  const [visibleFields, setVisibleFields] = React.useState({
    currentValues: true,
    newValue: true,
    confirmValues: true,
  });
  // function to show password
  const handleClickNewShowPassword = () => {
    setVisibleFields({ ...visibleFields, newValue: !visibleFields.newValue });
  };
  // function to show current password
  const handleClickCurrentShowPassword = () => {
    setVisibleFields({
      ...visibleFields,
      currentValues: !visibleFields.currentValues,
    });
  };
  // function to confirm password
  const handleClickConfirmShowPassword = () => {
    setVisibleFields({
      ...visibleFields,
      confirmValues: !visibleFields.confirmValues,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={passwordFrom}
        onClose={handlePasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="primary" variant="subtitle1">
              Change Password
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                handlePasswordClose();
                passwordForm.resetForm();
              }}
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            <CustomTextField
              id="txt_layout_change_password_current_password"
              name="currentPassword"
              autoComplete="new-password"
              InputLabelProps={{ shrink: false }}
              type={visibleFields.currentValues ? 'password' : 'text'}
              placeholder="Current Password"
              {...passwordForm.getFieldProps('currentPassword')}
              errorMessege={
                passwordForm.touched.currentPassword &&
                passwordForm.errors.currentPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickCurrentShowPassword}
                      edge="end"
                    >
                      {visibleFields.currentValues ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              id="txt_layout_change_password_new_password"
              InputLabelProps={{ shrink: false }}
              type={visibleFields.newValue ? 'password' : 'text'}
              name="newPassword"
              autoComplete="off"
              placeholder="New Password"
              {...passwordForm.getFieldProps('newPassword')}
              errorMessege={
                passwordForm.touched.newPassword &&
                passwordForm.errors.newPassword
              }
              helperText={
                passwordForm.touched.newPassword &&
                passwordForm.errors.newPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickNewShowPassword} edge="end">
                      {visibleFields.newValue ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              id="txt_layout_change_password_conform_password"
              InputLabelProps={{ shrink: false }}
              type={visibleFields.confirmValues ? 'password' : 'text'}
              name="conformPassword"
              autoComplete="off"
              placeholder="Confirm Password"
              {...passwordForm.getFieldProps('conformPassword')}
              errorMessege={
                passwordForm.touched.conformPassword &&
                passwordForm.errors.conformPassword
              }
              helperText={
                passwordForm.touched.conformPassword &&
                passwordForm.errors.conformPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickConfirmShowPassword}
                      edge="end"
                    >
                      {visibleFields.confirmValues ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={passwordForm.handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ChangePasswordForm);
ChangePasswordForm.propTypes = {
  passwordFrom: PropTypes.bool.isRequired,
  handlePasswordClose: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
