import { Assets } from '../assets/index';

export const ICON_GREEN = {
  'Admin Dashboard': Assets.access_management_icon_green,
  'Access Management': Assets.access_management_icon_green,
  'Initial Screening': Assets.initial_icon_green,
  'Ongoing Monitoring': Assets.ongoing_icon_green,
  'Analyst Dashboard': Assets.dashboard_Icon_green,
  'Manager Dashboard': Assets.dashboard_Icon_green,
  'Initiate Search': Assets.initiate_icon_green,
  'Manager Setup': Assets.ruleEngine_icon_green,
  'Sanctions Configuration': Assets.sanction_configuration_icon_green,
  'Download': Assets.download_Icon_green,
  TTR: Assets.ttr_Icon_green,
  Costing: Assets.costing_icon_green,
  Referrals: Assets.referral_icon_green,
  'Risk Rating Dashboard': Assets.risk_rating_dashboard_icon_green,
  History: Assets.history_green_icon,
};
export const ICON_BLUE = {
  'Admin Dashboard': Assets.access_management_icon,
  'Access Management': Assets.access_management_icon,
  'Initial Screening': Assets.initial_icon,
  'Ongoing Monitoring': Assets.ongoing_icon,
  'Analyst Dashboard': Assets.dashboard_Icon,
  'Manager Dashboard': Assets.dashboard_Icon,
  'Initiate Search': Assets.initiate_icon,
  'Manager Setup': Assets.ruleEngine_icon,
  'Sanctions Configuration': Assets.sanction_configuration_icon,
  'Download': Assets.download_Icon,
  TTR: Assets.ttr_Icon,
  Costing: Assets.costing_icon,
  Referrals: Assets.referral_icon,
  'Risk Rating Dashboard': Assets.risk_rating_dashboard_icon,
  History: Assets.history_blue_icon,
};
