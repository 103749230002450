// @ts-nocheck
import React from 'react';
import { Box, Backdrop } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Assets } from '../../assets/index';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme?.zIndex?.modal + 1,
  },
  loader: {
    width: 100,
    height: 150,
  },
}));
/**
 * @function PageLevelLoader
 * @description build UI for displaying the loader whenever user clicks on any new page
 * @returns {React.ReactElement} UI of PageLevelLoader
 * @example <PageLevelLoader />
 */
export default function PageLevelLoader() {
  const classes = useStyles();
  const [showLoader, setShowLoader] = React.useState(false);
  /**
   * @function showLoaderHandler
   * @description function for displaying the loader icon
   * @returns {undefined}
   * @example showLoaderHandler()
   */
  const showLoaderHandler = () => {
    setShowLoader(true);
  };
  /**
   * @function hideLoaderHandler
   * @description function for hiding the loader icon
   * @returns {undefined}
   * @example hideLoaderHandler()
   */
  const hideLoaderHandler = () => {
    setShowLoader(false);
  };
  React.useEffect(() => {
    document.addEventListener('showLoader', showLoaderHandler, false);
    document.addEventListener('hideLoader', hideLoaderHandler, false);
  }, []);
  return (
    <Box>
      {showLoader ? (
        <>
          <Backdrop className={classes.backdrop} open={showLoader}>
            <Box>
              <img className={classes.loader} src={Assets.loader} alt="" />
            </Box>
          </Backdrop>
        </>
      ) : null}
    </Box>
  );
}
