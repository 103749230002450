import useQuery from './useQuery';
import useWidth from './useWidth';
import useAccessManagement from './useAccessManagement';
import useAdmin from './useAdmin';
import useAssignSearches from './useAssignSearches';
import useAuth from './useAuth';
import useCosting from './useCosting';
import useDashBoard from './useDashboard';
import useDownloads from './useDownloads';
import useInitialScreening from './useInitialScreening';
import useInitiate from './useInitiate';
import useOngoingScreening from './useOngoingScreening';
import useReferrals from './useReferrals';
import useRuleEngine from './useRuleEngine';
import useSanctionConfiguration from './useSanctionConfiguration';
import useUser from './useUser';
import UsePreventReload from './UsePreventReload';
import useCommon from './useCommon';

export const Hooks = {
  useQuery,
  useWidth,
  useAccessManagement,
  useAdmin,
  useAssignSearches,
  useAuth,
  useCosting,
  useDashBoard,
  useDownloads,
  useInitialScreening,
  useInitiate,
  useOngoingScreening,
  useReferrals,
  useRuleEngine,
  useSanctionConfiguration,
  useUser,
  UsePreventReload,
  useCommon,
};
