// @ts-nocheck
import { useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import { useSelector } from 'react-redux';
import { ExcelRenderer } from 'react-excel-renderer';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';
import { Constants } from '../const';
import { Utils } from '../utils';
import useDashBoard from './useDashboard';
// import useExclusion from './useExclusion';
import useBulkFileUpload from './useBulkFileUpload';

const { AMS_NAME_COLUMNS, RISK_RATING_COLUMNS, ADD_TO_EXCLUSION_COLUMNS } =
  Constants.CommonConst;

const useRuleEngine = () => {
  const [rules, setRules] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const { user } = useSelector(selectAuth);
  const [changeRuleSettings, setChangeRuleSettings] = useState(false);
  const [exclusionData, setExclusionData] = useState([]);
  const [getExclusionData, setGetExclusionData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [batchID, setBatchID] = useState([]);
  const [bagofWords, setBagOfWords] = useState(null);
  const [suppressions, setSuppressions] = useState([]);
  const [suppressionId, setSuppressionId] = useState(0);
  const [fetchUsersPageCount, setFetchUsersPageCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalUsers, setTotalUsers] = useState(0);
  const fetchIdRef = useRef(0);
  const [remainingData, setRemainingData] = useState();
  const [trustedSources, setTrustedSources] = useState([]);
  const [sanctionList, setSanctionList] = useState([]);
  const [remainingSanctionList, setRemainingSanctionList] = useState([]);
  const [commonRule, setCommonRule] = useState([]);
  const [riskRatingParameters, setRiskRatingParameters] = useState([]);
  const [
    riskRatingParameterClassification,
    setRiskRatingParameterClassification,
  ] = useState([]);
  const { userTypes, analystType } = useDashBoard();
  const [parameterList, setParameterList] = useState([]);
  const [keywordData, setKeywordData] = useState([]);
  const [totalKeywordData, setTotalKeywordData] = useState(0);
  const [keywordDataPageCount, setKeywordDataPageCount] = useState(0);
  const [keywordDataFetched, setKeywordDataFetched] = useState(true);
  const [nameData, setNameData] = useState([]);
  const [totalNameData, setTotalNameData] = useState(0);
  const [nameDataPageCount, setNameDataPageCount] = useState(0);
  const [nameDataFetched, setNameDataFetched] = useState(true);
  const [nameFileData, setNamefileData] = useState(null);
  const [keywordFileData, setKeywordfileData] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [occupationFileData, setOccupationFileData] = useState(null);
  const [productFileData, setProductFileData] = useState(null);
  const [industryFileData, setIndustryFileData] = useState(null);
  const [reinitiateSearchesDetails, setReinitiateSearchesDetails] = useState(
    []
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [reportUrl, setReportUrl] = useState(null);
  // const { fileSize } = useExclusion();

  const {
    onCompleteMultipartUpload: onCompleteMultipartUploadExclusion,
    fetchPresignedUrlForEachChunk: fetchPresignedUrlForEachChunkExclusion,
    initiateFileUpload: initiateFileUploadExclusion,
  } = useBulkFileUpload();

  const { startUpload: startUploadExclusion, progress: progressExclusion } =
    useMultipartFileUploadToS3({
      startUploadCallback: initiateFileUploadExclusion,
      fetchPresignedUrlForEachChunk: fetchPresignedUrlForEachChunkExclusion,
      onCompleteMultipartUpload: onCompleteMultipartUploadExclusion,
    });

  const verifyAddToExclusionFile = (file) => {
    setIsUploading(true);
    return ExcelRenderer(file).then(
      (resp) => {
        const cols = resp.rows[0];
        const size = resp.rows.length - 1;
        setFileSize(size);
        setIsUploading(false);
        if (file?.type === 'application/vnd.oasis.opendocument.spreadsheet') {
          return;
        }
        return Utils.CoreUtils.validateFileColumns(
          cols,
          ADD_TO_EXCLUSION_COLUMNS
        );
      },
      (err) => {
        enqueueSnackbar('Error while processing the file', {
          variant: 'error',
        });
        setIsUploading(false);
        throw err;
      }
    );
  };

  /**
   * @function importBulkFile
   * @description A function to upload file
   * @returns {Promise} returns an axios promise
   */
  const importBulkFile = (payload) => {
    return axios
      .post(API_ENDPOINTS.RULE_ENGINE_BULK_AMS_CRAWLER, payload)
      .then((res) => {
        setTimeout(() => {
          setNamefileData(null);
          setKeywordfileData(null);
        }, 100);
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(
          'An error occurred while uploading the file. Please try again later.',
          {
            variant: 'warning',
          }
        );
        throw error;
      });
  };

  /**
   * @function initiateFileUpload
   * @description A function to upload files
   * @returns {Promise} returns an axios promise
   */
  // MULTI PART UPLOAD
  const initiateFileUpload = (fileDetails, successCallback, errorCallback) => {
    axios.post(API_ENDPOINTS.MULTIPART_UPLOAD_START_UPLOAD, fileDetails).then(
      (resp) => {
        if (successCallback) {
          successCallback(resp);
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
  /**
   * @function fetchPresignedUrlForEachChunk
   * @description A function to post url
   * @returns {Promise} returns an axios promise
   */
  const fetchPresignedUrlForEachChunk = (chunkDetails) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_GET_PRESIGNED_URL, chunkDetails)
      .then();

  /**
   * @function onCompleteMultipartUpload
   * @description A function to upload files in multiple parts
   * @returns {Promise} returns an axios promise
   */
  const onCompleteMultipartUpload = (
    fileChunksSummary,
    successCallback,
    errorCallback
  ) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_COMPLETE, {
        ...fileChunksSummary,
      })
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  const { startUpload: startBulkUpload, progress: bulkProgress } =
    useMultipartFileUploadToS3({
      startUploadCallback: initiateFileUpload,
      fetchPresignedUrlForEachChunk,
      onCompleteMultipartUpload,
    });
  const { startUpload: startKeywordBulkUpload, progress: keywordBulkProgress } =
    useMultipartFileUploadToS3({
      startUploadCallback: initiateFileUpload,
      fetchPresignedUrlForEachChunk,
      onCompleteMultipartUpload,
    });

  /**
   * @function verifyNameFile
   * @description Verify and validate the columns of an uploaded file.
   * @param {File} file - The uploaded file to be verified.
   * @param {string} fileType - The type of the file (e.g., 'excel', 'csv').
   * @returns {Promise|undefined} A promise that resolves if the file columns are valid for the specified file type. If the file is in a format that doesn't require column validation (e.g., ODS), this function returns `undefined`.
   * @throws {Error} If there is an error while processing the file, an error is thrown, and an error notification is displayed.
   */
  const verifyNameFile = (file, fileType) => {
    return ExcelRenderer(file).then(
      (resp) => {
        const cols = resp.rows[0];
        if (file?.type === 'application/vnd.oasis.opendocument.spreadsheet') {
          return;
        }
        return Utils.CoreUtils.validateFileColumns(
          cols,
          AMS_NAME_COLUMNS[fileType]
        );
      },
      (err) => {
        enqueueSnackbar('Error while processing the file', {
          variant: 'error',
        });
        throw err;
      }
    );
  };
  /**
   * @function fetchBagOfWords
   * @description A function to fetch Bag Of Words
   * @returns {Promise} returns an axios promise
   */
  const fetchBagOfWords = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_BAG_OF_WORDS, {
        params: queryParams,
      })
      .then(
        (response) => {
          setBagOfWords(response?.data);
          setIsLoading(false);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchTrustedSources
   * @description A function to fetch trusted urls data
   * @returns {Promise} returns an axios promise
   */
  const fetchTrustedSources = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.RULE_ENGINE_TRUSTED_SOURCES).then(
      (response) => {
        setIsLoading(false);
        setTrustedSources(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function addBagOfWords
   * @description A function to add new Bag Of Words
   * @returns {Promise} returns an axios promise
   */
  const addBagOfWords = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.RULE_ENGINE_BAG_OF_WORDS, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function addCrawlerDetails
   * @description Add crawler details function to post user input.
   * @param {object} data - The data containing crawler details to be added.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */
  const addCrawlerDetails = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.RULE_ENGINE_AMS_CRAWLER, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchKeywordCrawlerDetails
   * @description Fetch keyword crawler details added by user.
   * @param {object} data - The data containing parameters for the fetch request.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */
  const fetchKeywordCrawlerDetails = useCallback((data) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_AMS_CRAWLER, {
        params: {
          page_size: data?.pageSize,
          page: data?.page + 1,
          keyword_type: data?.keyword_type,
          search_keyword: data?.search_keyword.trim(),
          manager_id: data?.manager_id,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (data?.keyword_type === 'k') {
            setKeywordData([...response?.data?.data]);
            setTotalKeywordData(response.headers['x-total-count']);
            setKeywordDataPageCount(
              Math.ceil(response.headers['x-total-count'] / data?.pageSize)
            );
            if (!Number(response.headers['x-total-count'])) {
              setKeywordDataFetched(false);
            }
          } else {
            setNameData([...response?.data?.data]);
            setTotalNameData(response.headers['x-total-count']);
            setNameDataPageCount(
              Math.ceil(response.headers['x-total-count'] / data?.pageSize)
            );
            if (!Number(response.headers['x-total-count'])) {
              setNameDataFetched(false);
            }
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  }, []);
  /**
   * @function addTrustedSources
   * @description A function to add Trusted Sources link
   * @returns {Promise} returns an axios promise
   */
  const addTrustedSources = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.RULE_ENGINE_TRUSTED_SOURCES, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateBagOfWords
   * @description A function to update Bag Of Words
   * @returns {Promise} returns an axios promise
   */
  const updateBagOfWords = (data) => {
    const uid = data.bag_of_words;
    const payLoad = {
      ...uid,
      ...(data?.manager_id && { manager_id: data?.manager_id }),
    };
    setIsLoading(true);
    return axios
      .patch(API_ENDPOINTS.RULE_ENGINE_BAG_OF_WORDS, payLoad)
      .then(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchSearchRule
   * @description A function to fetch search rule
   * @returns {Promise} returns an axios promise
   */
  const fetchSearchRule = (data) => {
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_SEARCH_RULE_UPDATE, {
        data,
        params: {
          list_type: data.list_type,
          manager_id: data?.manager_id,
        },
      })
      .then(
        (response) => {
          if (response.data !== undefined) {
            if (['p', 'l', 'a', 'c'].includes(data.list_type)) {
              setRules(response.data ?? {});
            } else setRules(response.data);
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function createSearchRule
   * @description A function create new search rule
   * @returns {Promise} returns an axios promise
   */
  const createSearchRule = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.RULE_ENGINE_SEARCH_RULE_UPDATE, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
          enqueueSnackbar('Rule Created Successfully', {
            variant: 'success',
          });
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateSearchRules
   * @description A function to update search rule
   * @returns {Promise} returns an axios promise
   */
  const updateSearchRules = (data) => {
    const body = {
      ...data,
      address_and_condition: !data?.address_and_condition,
      manager_id: data?.manager_id,
    };
    setIsLoading(true);
    return axios
      .patch(`${API_ENDPOINTS.RULE_ENGINE_SEARCH_RULE_UPDATE}${data?.id}`, body)
      .then(
        () => {
          setIsLoading(false);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function deleteSearchRule
   * @description A function to delete Search Rule
   * @returns {Promise} returns an axios promise
   */
  const deleteSearchRule = (id, data) => {
    setIsLoading(true);
    const param = {
      manager_id: data,
    };
    return axios
      .delete(`${API_ENDPOINTS.RULE_ENGINE_SEARCH_RULE_UPDATE}${id}`, {
        ...(data && { data: param }),
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            enqueueSnackbar('Rule deleted', {
              variant: 'success',
            });
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchRemainingSanctionList
   * @description Fetch the remaining sanction list.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */

  const fetchRemainingSanctionList = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_REMAINING_SANCTION_LIST, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRemainingSanctionList(response?.data);
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateSanctionsFilter
   * @description A function to update Sanctions Filter
   * @returns {Promise} returns an axios promise
   */
  const updateSanctionsFilter = (data) => {
    const body = {
      date: {
        Selected: data?.isCheck,
        endDate: data?.End_Date,
        startDate: data?.Start_Date,
      },
      List: {
        Selected: data?.selected,
        list: data?.list,
      },
    };

    axios.post(API_ENDPOINTS.RULE_ENGINE_DELTA_CHANGE, body).then(
      (response) => {
        if (response) {
          enqueueSnackbar('Rules updated', {
            variant: 'success',
          });
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchPrivateList
   * @description A function to fetch Batch ID
   * @returns {Promise} returns an axios promise
   */
  const fetchBatchID = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.RULE_ENGINE_BATCH_ID).then(
      (response) => {
        setIsLoading(false);
        setBatchID(response?.data?.batch_id);
        setBatchList(response?.data?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function addBatchID
   * @description A function to add Batch ID
   * @returns {Promise} returns an axios promise
   */
  const addBatchID = (data) => {
    setIsLoading(true);
    const body = {
      data: {
        batch_id: data?.batchID,
        batch_name: data?.batchName,
        created_date: data?.created_date,
        description: data?.desc,
      },
    };
    return axios
      .post(API_ENDPOINTS.RULE_ENGINE_ADD_EXCLUSION_LIST, body.data)
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            enqueueSnackbar('Batch Updated', {
              variant: 'success',
            });
          }
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchAddToExclusionList
   * @description A function to fetch data to add them in exclusion list
   * @returns {Promise} returns an axios promise
   */
  const fetchAddToExclusionList = (data) => {
    setRemainingData(data);
    const modifiedData = { ...data };
    if (modifiedData.listType && Array.isArray(modifiedData.listType)) {
      modifiedData.listType.forEach((item) => {
        modifiedData[item] = true;
      });
      delete modifiedData.listType;
    }
    const queryParams = {
      ...modifiedData,
      filter_type: modifiedData.filterType?.trim(),
      Sanctions: modifiedData.Sanctions !== undefined,
      PEP: modifiedData.PEP !== undefined,
      AMS: modifiedData.AMS !== undefined,
      start_date: modifiedData.Start_Date,
      end_date: modifiedData.End_Date,
      page_size: modifiedData.pageSize,
    };
    if (queryParams?.batchID) {
      delete queryParams?.batchID;
    }
    delete queryParams?.filterType;
    delete queryParams.Start_Date;
    delete queryParams.End_Date;
    delete queryParams.pageSize;
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_FILTER_EXCLUSION_LIST, {
        data: {
          loader: false,
        },
        params: queryParams,
      })
      .then(
        (res) => {
          setIsLoading(false);
          return res;
        },
        (err) => {
          setIsLoading(false);
          setIsError(err);
        }
      );
  };
  /**
   * @function fetchExclusionList
   * @description A function to fetch Exclusion List
   * @returns {Promise} returns an axios promise
   */
  const fetchExclusionList = (queryParams) => {
    setRemainingData(queryParams);
    const query = { ...queryParams };
    if (query?.listType && Array.isArray(query?.listType)) {
      query?.listType.forEach((item) => {
        query[item] = true;
      });
      delete query?.listType;
    }
    const data = {
      ...query,
      filter_type: query?.filter_type?.trim(),
      Sanctions: query?.Sanction !== undefined,
      PEP: query?.PEP !== undefined,
      AMS: query?.AMS !== undefined,
      page_size: query?.pageSize,
      batch_id: query?.batchID,
      loader: false,
    };
    delete data.pageSize;
    delete data.batchID;
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_EXCLUSION_LIST, {
        data: {
          loader: false,
        },
        params: data,
      })
      .then(
        (res) => {
          setIsLoading(false);
          setGetExclusionData(res?.data?.data);
          return res;
        },
        (err) => {
          setIsLoading(false);
          setIsError(err);
        }
      );
  };
  /**
   * @function addExclusion
   * @description A function to add the data to exclusion list
   * @returns {Promise} returns an axios promise
   */
  const addExclusion = (data) => {
    const body = {
      batch_name: data?.batchData?.name,
      date: data?.batchData?.date,
      batch_id: data?.batchData?.id,
      description: data?.values?.desc,
      review_id: data?.selected,
      upload_id: data?.upload_id,
      file_size: data?.fileSize,
    };
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.RULE_ENGINE_FINAL_ADD_EXCLUSION_LIST, body)
      .then(
        (res) => {
          fetchAddToExclusionList(remainingData);
          setIsLoading(false);
          if (!data?.upload_id) {
            enqueueSnackbar('Added successfully', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Uploaded successfully', {
              variant: 'success',
            });
          }
          return res;
        },
        (err) => {
          setIsLoading(false);
          setIsError(err);
          throw err;
        }
      );
  };

  const download = (did) => {
    const params = did;
    setDownloadDisabled(true);
    return axios.get(`${API_ENDPOINTS.DOWNLOAD_EXCLUSION_FILES}${params}`).then(
      (response) => {
        if (response?.status === 200) {
          if (response.data?.Msg) {
            enqueueSnackbar(response.data?.Msg, {
              variant: 'success',
            });
          } else {
            window.open(response.data?.excelURL);
          }
        }
        setDownloadDisabled(false);
        return response;
      },
      (e) => {
        setIsError(e);
        setDownloadDisabled(false);
        throw e;
      }
    );
  };

  /**
   * @function removeExclusion
   * @description A function to remove the data from exclution list
   * @returns {Promise} returns an axios promise
   */
  const removeExclusion = (selected) => {
    const body = {
      review_id: selected,
    };

    setIsLoading(true);
    return axios
      .delete(API_ENDPOINTS.RULE_ENGINE_REMOVE_EXCLUSION_LIST, { data: body })
      .then(
        (res) => {
          fetchExclusionList(remainingData);
          setIsLoading(false);
          enqueueSnackbar('Removed successfully', {
            variant: 'success',
          });
          return res;
        },
        (err) => {
          setIsLoading(false);
          setIsError(err);
          throw err;
        }
      );
  };
  /**
   * @function getSuppressionLogic
   * @description A function to get Suppression Logic data
   * @returns {Promise} returns an axios promise
   */
  const getSuppressionLogic = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_SUPPRESSION_LOGIC, {
        params: {
          ...(queryParams?.manager_id && {
            manager_id: queryParams?.manager_id,
          }),
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setSuppressions(response?.data);
          setSuppressionId(response?.data[0].id);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function updateSuppressionLogic
   * @description A function to update Suppression Logic
   * @returns {Promise} returns an axios promise
   */
  const updateSuppressionLogic = (data) => {
    const param = {
      ...data,
      show_all_cases: !data?.show_all_cases,
      show_rejected_cases: !data?.show_rejected_cases,
    };
    setIsLoading(true);
    return axios
      .patch(
        `${API_ENDPOINTS.RULE_ENGINE_SUPPRESSION_LOGIC}${suppressionId}/`,
        param
      )
      .then((response) => {
        setIsLoading(false);
        if (response?.data) {
          enqueueSnackbar('Rules updated', {
            variant: 'success',
          });
        }
      });
  };
  const fetchReinitiatedDetails = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.SUPPRESSION_LOGIC_REINITIATE_SEARCHES, {
        loader: false,
        params: {
          manager_id: queryParams?.manager_id,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setReinitiateSearchesDetails(response?.data);
        },
        (e) => {
          setReinitiateSearchesDetails(e);
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  const updateReinitiateSearches = (data) => {
    setIsLoading(true);
    const param = { ...data };
    return axios
      .post(API_ENDPOINTS.SUPPRESSION_LOGIC_REINITIATE_SEARCHES, null, {
        params: param,
      })
      .then(
        () => {
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          setIsError(error);
        }
      );
  };
  const stopReinitiateSearches = (data) => {
    setIsLoading(true);
    return axios
      .put(API_ENDPOINTS.SUPPRESSION_LOGIC_REINITIATE_SEARCHES, data)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar(
            'The reinitiation of the searches has been stopped.',
            {
              variant: 'success',
            }
          );
          fetchReinitiatedDetails();
        },
        (error) => {
          setIsLoading(false);
          setIsError(error);
        }
      );
  };

  /**
   * @function fetchUsers
   * @description Fetch a list of users based on the provided parameters.
   *
   * @param {number} pageSize - The number of items to fetch per page.
   * @param {number} pageIndex - The current page index.
   * @param {string} query - The search query to filter users.
   * @param {string} orderBy - The field to order users by.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */

  const fetchUsers = useCallback(({ pageSize, pageIndex, query, orderBy }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = fetchIdRef.current;
    fetchIdRef.current += 1;

    // Your server could send back total page count.
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.RULE_ENGINE_CARD_DATA, {
        params: {
          page: pageIndex + 1,
          screen: 'initial',
          ...(pageSize ? { pagesize: pageSize } : {}),
          query,
          orderBy,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (fetchId === fetchIdRef.current) {
            setUserList(response.data);
            setTotalUsers(response.headers['x-total-count']);
            setFetchUsersPageCount(
              Math.ceil(response.headers['x-total-count'] / pageSize)
            );
          }
        },
        (err) => {
          setIsLoading(false);
          setIsError(err);
        }
      );
  }, []);
  /**
   * @function fetchSanctionList
   * @description Fetch a list of sanctioned configurations.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */

  const fetchSanctionList = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.Sanction_Configuration_TREE_DATA, {
        params: {
          checked: 'y',
          manager_id: queryParams?.manager_id,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setSanctionList(response?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchCommonRule
   * @description Fetch the common rule configuration.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */

  const fetchCommonRule = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.RULE_ENGINE_COMMON).then(
      (response) => {
        setIsLoading(false);
        setCommonRule(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateCommonRule
   * @description Update the common rule configuration.
   * @param {object} data - The data to be updated.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */

  const updateCommonRule = (data) => {
    const body = {
      ...data,
      address_and_condition: !data?.address_and_condition,
    };
    setIsLoading(true);
    return axios.patch(API_ENDPOINTS.RULE_ENGINE_COMMON, body).then(
      (response) => {
        if (response) {
          enqueueSnackbar(response?.data?.Msg, {
            variant: 'success',
          });
          setIsLoading(false);
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchCountryList
   * @description Fetch the list of countries. This function sends a request to fetch the list of countries.
   * @returns {Promise} A promise that resolves with the response data if the request is successful.
   * @throws {Error} If there is an error during the request, an error is thrown, and the error state is updated.
   */

  const fetchCountryList = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.RULE_ENGINE_GET_COUNTRY).then(
      (response) => {
        setIsLoading(false);
        setCountryList(response?.data?.Msg);
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  const fetchRiskRatingParameters = (searchType, admin) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_RISK_RATING_PARAMETERS, {
        params: {
          search_type: searchType,
          manager_id: admin,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRiskRatingParameters(response?.data);
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const updateRiskRatingParameters = (data) => {
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.RULE_ENGINE_RISK_RATING_PARAMETERS, data)
      .then(
        (response) => {
          if (response.data !== undefined) {
            setIsLoading(false);
            enqueueSnackbar('Parameter weightage assigned successfully', {
              variant: 'success',
            });
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const reInitiateSearches = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.RISK_RATING_REKYC, data).then(
      (response) => {
        if (response.data !== undefined) {
          setIsLoading(false);
          enqueueSnackbar('Searches Re-initiated successfully', {
            variant: 'success',
          });
        }
        return response;
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };
  const fetchRiskRatingParametersClassification = (searchType, admin) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_RISK_RATING_PARAMETER_CLASSIFICATION, {
        params: {
          search_type: searchType,
          manager_id: admin,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRiskRatingParameterClassification(response?.data);
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const updateRiskRatingParametersClassification = (data) => {
    setIsLoading(true);
    return axios
      .post(
        API_ENDPOINTS.RULE_ENGINE_RISK_RATING_PARAMETER_CLASSIFICATION,
        data
      )
      .then(
        (response) => {
          if (response.data !== undefined) {
            setIsLoading(false);
            enqueueSnackbar('Parameter details configured successfully', {
              variant: 'success',
            });
          }
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const fetchParametersList = (admin) => {
    const queryParam = {
      link: true,
      manager_id: admin,
    };
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RULE_ENGINE_GET_PARAMETER_LIST, { params: queryParam })
      .then(
        (response) => {
          setIsLoading(false);
          setParameterList(response?.data);
          return response;
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const deleteParameterFile = (data) => {
    setIsLoading(true);
    return axios
      .delete(API_ENDPOINTS.RULE_ENGINE_GET_PARAMETER_LIST, { data })
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('File deleted successfully', {
            variant: 'success',
          });
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const {
    startUpload: startOccupationBulkUpload,
    progress: occupationBulkProgress,
  } = useMultipartFileUploadToS3({
    startUploadCallback: initiateFileUpload,
    fetchPresignedUrlForEachChunk,
    onCompleteMultipartUpload,
  });
  const {
    startUpload: startIndustryBulkUpload,
    progress: industryBulkProgress,
  } = useMultipartFileUploadToS3({
    startUploadCallback: initiateFileUpload,
    fetchPresignedUrlForEachChunk,
    onCompleteMultipartUpload,
  });
  const { startUpload: startProductBulkUpload, progress: productBulkProgress } =
    useMultipartFileUploadToS3({
      startUploadCallback: initiateFileUpload,
      fetchPresignedUrlForEachChunk,
      onCompleteMultipartUpload,
    });
  const verifyRiskRatingBulkFile = (file, fileType) => {
    return ExcelRenderer(file).then(
      (resp) => {
        const cols = resp.rows[0];
        if (file?.type === 'application/vnd.oasis.opendocument.spreadsheet') {
          return;
        }
        return Utils.CoreUtils.validateFileColumns(
          cols,
          RISK_RATING_COLUMNS[fileType]
        );
      },
      (err) => {
        enqueueSnackbar('Error while processing the file', {
          variant: 'error',
        });
        throw err;
      }
    );
  };

  const importRiskRatingParameterBulkFile = (payload) => {
    return axios
      .post(API_ENDPOINTS.RISK_PARAMETERS, payload)
      .then((res) => {
        enqueueSnackbar('Bulk file uploaded successfully.', {
          variant: 'success',
        });
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(
          'An error occurred while uploading the file. Please try again later.',
          {
            variant: 'warning',
          }
        );
        throw error;
      });
  };

  const downloadExcel = async ({ params }) => {
    try {
      const response = await axios.get(API_ENDPOINTS.DOWNLOAD_RULE_ENGINE, {
        params,
      });

      const fileData = atob(response.data.file_data);

      const byteArray = new Uint8Array(fileData.length);
      for (let i = 0; i < fileData.length; i++) {
        byteArray[i] = fileData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], {
        type: response.data.content_type,
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.data.file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      enqueueSnackbar('Error while downloading the file', {
        variant: 'error',
      });
    }
  };

  const updatePepRule = (data) => {
    setIsLoading(true);
    return axios.patch(API_ENDPOINTS.PEP_CONFIGURATION, data).then(
      () => {
        setIsLoading(false);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  const createPepRule = (data) => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.PEP_CONFIGURATION, data).then(
      () => {
        setIsLoading(false);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  return {
    isError,
    isLoading,
    user,
    rules,
    setRules,
    updateSearchRules,
    createSearchRule,
    deleteSearchRule,
    updateSanctionsFilter,
    fetchSearchRule,
    changeRuleSettings,
    setChangeRuleSettings,
    fetchBatchID,
    addBatchID,
    fetchUsers,
    userList,
    setExclusionData,
    exclusionData,
    fetchUsersPageCount,
    batchID,
    fetchAddToExclusionList,
    fetchExclusionList,
    batchList,
    addExclusion,
    setGetExclusionData,
    getExclusionData,
    removeExclusion,
    remainingData,
    fetchBagOfWords,
    bagofWords,
    trustedSources,
    fetchTrustedSources,
    addTrustedSources,
    addBagOfWords,
    updateBagOfWords,
    getSuppressionLogic,
    setSuppressions,
    suppressions,
    updateSuppressionLogic,
    fetchSanctionList,
    setSanctionList,
    sanctionList,
    remainingSanctionList,
    fetchRemainingSanctionList,
    commonRule,
    setCommonRule,
    fetchCommonRule,
    updateCommonRule,
    // updateDeltaChangeFrequency,
    // fetchDeltaChangeFrequency,
    // deltaChangeFrequency,
    fetchCountryList,
    countryList,
    fetchRiskRatingParameters,
    riskRatingParameters,
    updateRiskRatingParameters,
    updateRiskRatingParametersClassification,
    fetchParametersList,
    parameterList,
    fetchRiskRatingParametersClassification,
    riskRatingParameterClassification,
    addCrawlerDetails,
    fetchKeywordCrawlerDetails,
    keywordDataFetched,
    keywordDataPageCount,
    totalKeywordData,
    keywordData,
    totalNameData,
    nameDataPageCount,
    nameDataFetched,
    nameData,
    setNameDataFetched,
    setKeywordDataFetched,
    nameFileData,
    setNamefileData,
    verifyNameFile,
    importBulkFile,
    bulkProgress,
    startBulkUpload,
    keywordBulkProgress,
    startKeywordBulkUpload,
    keywordFileData,
    setKeywordfileData,
    occupationBulkProgress,
    startOccupationBulkUpload,
    industryBulkProgress,
    startIndustryBulkUpload,
    productBulkProgress,
    startProductBulkUpload,
    verifyRiskRatingBulkFile,
    setIndustryFileData,
    setProductFileData,
    setOccupationFileData,
    industryFileData,
    productFileData,
    occupationFileData,
    importRiskRatingParameterBulkFile,
    deleteParameterFile,
    reinitiateSearchesDetails,
    fetchReinitiatedDetails,
    updateReinitiateSearches,
    stopReinitiateSearches,
    reInitiateSearches,
    userTypes,
    analystType,
    updatePepRule,
    createPepRule,
    progressExclusion,
    startUploadExclusion,
    verifyAddToExclusionFile,
    isUploading,
    fileSize,
    download,
    reportUrl,
    downloadDisabled,
    setDownloadDisabled,
    downloadExcel,
  };
};
export default useRuleEngine;
