import { createSlice } from '@reduxjs/toolkit';

import moment from 'moment';
import { Constants } from '../../const';

const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
const today = moment().format('YYYY-MM-DD');
const { SEARCH_TYPES, SCREEN_TYPES } = Constants.CommonConst;

const initialState = {
  isOngoingMonitoringTableView: false,
  isInitialScreeningTableView: false,
  searchId: '',
  searchType: '',
  alertListType: [],
  alerted: '',
  targetStartDate: '',
  targetEndDate: '',
  status: '',
  alert: 20,
  bookmark: false,
  team: '',
  manager: '',
  analyst: '',
  horizontalTeam: '',
  risk_type: [],
  country: '',
  industry: '',
  occupation: '',
  onboarding_type: '',
  transaction_product_type: '',
  party: '',
  common_searchId: '',
  common_searchType: '',
  common_alertListType: [],
  common_alerted: '',
  common_targetStartDate: '',
  common_targetEndDate: '',
  common_status: '',
  common_alert: 20,
  common_bookmark: false,
  common_team: '',
  common_manager: '',
  common_analyst: '',
  common_horizontalTeam: '',
  common_channel: '',
  common_product: '',
  common_transaction_type: '',
  ongoingsearchId: '',
  ongoingsearchType: '',
  ongoingalertListType: [],
  ongoingalerted: '',
  ongoingtargetStartDate: '',
  ongoingtargetEndDate: '',
  ongoingstatus: '',
  ongoingalert: 20,
  ongoingbookmark: false,
  ongoingteam: '',
  ongoingmanager: '',
  ongoinganalyst: '',
  ongoinghorizontalTeam: '',
  ongoing_risk_type: [],
  ongoing_country: '',
  ongoing_industry: '',
  ongoing_occupation: '',
  ongoing_onboarding_type: '',
  ongoing_transaction_product_type: '',
  ongoing_party: '',
  common_ongoingsearchId: '',
  common_ongoingsearchType: '',
  common_ongoingalertListType: [],
  common_ongoingalerted: '',
  common_ongoingtargetStartDate: '',
  common_ongoingtargetEndDate: '',
  common_ongoingstatus: '',
  common_ongoingalert: 20,
  common_ongoingbookmark: false,
  common_ongoingteam: '',
  common_ongoingmanager: '',
  common_ongoinganalyst: '',
  common_ongoinghorizontalTeam: '',
  common_ongoingchannel: '',
  common_ongoingproduct: '',
  common_ongoingtransaction_type: '',
  initialPageSizeInitialScreening: 10,
  initialPageIndexInitialScreening: 0,
  initialPageSizeOngoingMonitoring: 10,
  initialPageIndexOngoingMonitoring: 0,
  channel: '',
  product: '',
  transaction_type: '',
  ongoingchannel: '',
  ongoingproduct: '',
  ongoingtransaction_type: '',
  downloadReportFilters: {
    screening_type: SCREEN_TYPES.i.key,
    dates: { Start_Date: null, End_Date: null },
    alerted: ['Alert'],
    listType: [],
    status: ['Accepted'],
    search_type: [SEARCH_TYPES.i.key],
    team: '',
    analyst_id: '',
    manager_id: '',
    baseStr: '',
    risk_type: [],
    country: [],
    industry: [],  
    occupation: [],
    onboarding_type:'',
    product: [],
    transaction_product_type: [],
    transaction_type: [],
    channel: [] 
  },
  historyFilters: {
    alerted: '',
    searchType: '',
    riskType: '',
    finalVerdict: '',
    team: '',
    analyst: '',
    initiatedStartDate: '',
    initiatedEndDate: '',
    verdictStartDate: '',
    verdictEndDate: '',
    screeningType: 'OngoingScreening',
  },
  downloadStatusFilters: {
    screening_type: SCREEN_TYPES.i.key,
    dates: { start_date: startOfMonth, end_date: today },
    search_type: SEARCH_TYPES.i.key,
    team: '',
    due_date: today,
    current_status: '',
    pending_with: '',
    final_verdict: '',
    manager_id: '',
  },
  riskRatingDashboardFilters: {
    screen_type: SCREEN_TYPES.o.key,
    search_type: '',
    start_date: '',
    end_date: '',
    duration: '',
    analyst: '',
    manager: '',
    team: '',
  },
  exclusionListFilter: {
    filter_type: '',
    batchID: '',
    search_type: '',
    listType: [],
  },
  addtoExclusionFilter: {
    filterType: '',
    Start_Date: '',
    search_type: '',
    End_Date: '',
    listType: [],
  },
  dashboardFilters: {
    manager_id: '',
    team: '',
    StartDate: '',
    EndDate: '',
    analyst: '',
    screen_type: 'InitialScreening',
    seach_initiated: '',
  },
};
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    ongoingMonitoringTableView: (state) => {
      state.isOngoingMonitoringTableView = !state.isOngoingMonitoringTableView;
    },
    initialScreeningTableView: (state) => {
      state.isInitialScreeningTableView = !state.isInitialScreeningTableView;
    },
    setInitialPageSizeInitialScreening: (state, action) => {
      state.initialPageSizeInitialScreening = action.payload
        ? action.payload
        : state.initialPageSizeInitialScreening;
    },
    setInitialPageIndexInitialScreening: (state, action) => {
      state.initialPageIndexInitialScreening = action.payload
        ? action.payload
        : state.initialPageIndexInitialScreening;
    },
    resetInitialPageIndexInitialScreening: (state) => {
      state.initialPageIndexInitialScreening = 0;
    },
    resetInitialPageSizeInitialScreening: (state) => {
      state.initialPageIndexInitialScreening = 10;
    },
    setInitialPageSizeOngoingMonitoring: (state, action) => {
      state.initialPageSizeOngoingMonitoring = action.payload
        ? action.payload
        : state.initialPageSizeOngoingMonitoring;
    },
    setInitialPageIndexOngoingMonitoring: (state, action) => {
      state.initialPageIndexOngoingMonitoring = action.payload
        ? action.payload
        : state.initialPageIndexOngoingMonitoring;
    },
    resetInitialPageIndexOngoingMonitoring: (state) => {
      state.initialPageIndexOngoingMonitoring = 0;
    },
    resetInitialPageSizeOngoingMonitoring: (state) => {
      state.initialPageSizeOngoingMonitoring = 10;
    },
    setDownloadReportFilters: (state, action) => {
      (state.downloadReportFilters.screening_type = action?.payload?.screening_type),
        (state.downloadReportFilters.dates.Start_Date = action?.payload?.dates?.Start_Date),
        (state.downloadReportFilters.dates.End_Date = action?.payload?.dates?.End_Date),
        (state.downloadReportFilters.alerted = action?.payload?.alerted),
        (state.downloadReportFilters.listType = action?.payload?.listType),
        (state.downloadReportFilters.status =  action?.payload?.status),
        (state.downloadReportFilters.search_type= action?.payload?.search_type),
        (state.downloadReportFilters.team = action?.payload?.team),
        (state.downloadReportFilters.analyst_id = action?.payload?.analyst_id),
        (state.downloadReportFilters.manager_id = action?.payload?.manager_id),
        (state.downloadReportFilters.baseStr= action?.payload?.baseStr),
        (state.downloadReportFilters.risk_type = action?.payload?.risk_type),
        (state.downloadReportFilters.country = action?.payload?.country),
        (state.downloadReportFilters.industry = action?.payload?.industry),
        (state.downloadReportFilters.occupation = action?.payload?.occupation),
        (state.downloadReportFilters.onboarding_type= action?.payload?.onboarding_type),
        (state.downloadReportFilters.product = action?.payload?.product);
        (state.downloadReportFilters.transaction_product_type = action?.payload?.transaction_product_type),
        (state.downloadReportFilters.transaction_type = action?.payload?.transaction_type),
        (state.downloadReportFilters.channel = action?.payload?.channel);
    },
    removeDownloadReportFilters: (state) => {
      (state.downloadReportFilters.screening_type = SCREEN_TYPES.i.key),
      (state.downloadReportFilters.dates.Start_Date = null),
      (state.downloadReportFilters.dates.End_Date = null),
      (state.downloadReportFilters.alerted = ['Alert']),
      (state.downloadReportFilters.listType = []),
      (state.downloadReportFilters.status = ['Accepted']),
      (state.downloadReportFilters.search_type = [SEARCH_TYPES.i.key]),
      (state.downloadReportFilters.team = null),
      (state.downloadReportFilters.analyst_id = ''),
      (state.downloadReportFilters.manager_id = ''),
      (state.downloadReportFilters.baseStr= ''),
      (state.downloadReportFilters.risk_type = []),
      (state.downloadReportFilters.country = []),
      (state.downloadReportFilters.industry = []),
      (state.downloadReportFilters.occupation = []),
      (state.downloadReportFilters.onboarding_type= ''),
      (state.downloadReportFilters.product = []),
      (state.downloadReportFilters.transaction_product_type = []),
      (state.downloadReportFilters.transaction_type = []),
      (state.downloadReportFilters.channel = [])
        },
    setHistoryFilters: (state, action) => {
      state.historyFilters = action.payload
        ? action.payload
        : initialState.historyFilters;
    },
    setDownloadStatusFilters: (state, action) => {
      state.downloadStatusFilters = action.payload
        ? action.payload
        : initialState.downloadStatusFilters;
    },
    setRiskRatingDashboardFilters: (state, action) => {
      state.riskRatingDashboardFilters = action.payload
        ? action.payload
        : initialState.riskRatingDashboardFilters;
    },
    setExclusionListFilter: (state, action) => {
      state.exclusionListFilter = action.payload
        ? action.payload
        : initialState.exclusionListFilter;
    },
    setAddToExclusionListFilter: (state, action) => {
      state.addtoExclusionFilter = action.payload
        ? action.payload
        : initialState.addtoExclusionFilter;
    },
    setDashboardFilters: (state, action) => {
      state.dashboardFilters = action.payload
        ? action.payload
        : initialState.dashboardFilters;
    },
    screenTypeData: (state, action) => {
      (state.searchId = action?.payload?.searchId),
        (state.searchType = action?.payload?.searchType),
        (state.alertListType = action?.payload?.alertListType),
        (state.alerted = action?.payload?.alerted),
        (state.targetStartDate = action?.payload?.targetStartDate),
        (state.targetEndDate = action?.payload?.targetEndDate),
        (state.status = action?.payload?.status),
        (state.alert = action?.payload?.alert),
        (state.bookmark = action?.payload?.bookmark),
        (state.team = action?.payload?.team),
        (state.analyst = action?.payload?.analyst),
        (state.manager = action?.payload?.manager),
        (state.horizontalTeam = action?.payload?.horizontalTeam),
        (state.channel = action?.payload?.channel),
        (state.product = action?.payload?.product),
        (state.transaction_type = action?.payload?.transaction_type),
        (state.country= action?.payload?.country),
        (state.industry= action?.payload.industry),
        (state.occupation= action?.payload?.occupation),
        (state.onboarding_type= action?.payload?.onboarding_type),
        (state.transaction_product_type= action?.payload?.transaction_product_type),
        (state.party= action?.payload?.party);
    },
    commonScreenTypeData: (state, action) => {
      (state.common_searchId = action?.payload?.common_searchId),
        (state.common_searchType = action?.payload?.common_searchType),
        (state.common_alertListType = action?.payload?.common_alertListType),
        (state.common_alerted = action?.payload?.common_alerted),
        (state.common_targetStartDate = action?.payload?.common_targetStartDate),
        (state.common_targetEndDate = action?.payload?.common_targetEndDate),
        (state.common_status = action?.payload?.common_status),
        (state.common_alert = action?.payload?.common_alert),
        (state.common_bookmark = action?.payload?.common_bookmark),
        (state.common_team = action?.payload?.common_team),
        (state.common_analyst = action?.payload?.common_analyst),
        (state.common_manager = action?.payload?.common_manager),
        (state.common_horizontalTeam = action?.payload?.common_horizontalTeam),
        (state.common_channel = action?.payload?.common_channel),
        (state.common_product = action?.payload?.common_product),
        (state.common_transaction_type = action?.payload?.common_transaction_type);
    },
    removeScreenTypeData: (state) => {
      (state.searchId = ''),
        (state.searchType = ''),
        (state.alertListType = []),
        (state.alerted = ''),
        (state.targetStartDate = null),
        (state.targetEndDate = null),
        (state.status = ''),
        (state.alert = 20),
        (state.bookmark = ''),
        (state.team = ''),
        (state.analyst = ''),
        (state.horizontalTeam = ''),
        (state.manager = ''),
        (state.channel = ''),
        (state.product = ''),
        (state.transaction_type = ''),
        (state.country= ''),
        (state.industry= ''),
        (state.occupation= ''),
        (state.onboarding_type= ''),
        (state.transaction_product_type= ''),
        (state.party= '')
    },
    commonRemoveScreenTypeData: (state) => {
      (state.common_searchId = ''),
        (state.common_searchType = ''),
        (state.common_alertListType = []),
        (state.common_alerted = ''),
        (state.common_targetStartDate = null),
        (state.common_targetEndDate = null),
        (state.common_status = ''),
        (state.common_alert = 20),
        (state.common_bookmark = ''),
        (state.common_team = ''),
        (state.common_analyst = ''),
        (state.common_horizontalTeam = ''),
        (state.common_manager = ''),
        (state.common_channel = ''),
        (state.common_product = ''),
        (state.common_transaction_type = '');
    },
    ongoingScreenTypeData: (state, action) => {
      (state.ongoingsearchId = action?.payload?.ongoingsearchId),
        (state.ongoingsearchType = action?.payload?.ongoingsearchType),
        (state.ongoingalertListType = action?.payload?.ongoingalertListType),
        (state.ongoingalerted = action?.payload?.ongoingalerted),
        (state.ongoingtargetStartDate =
          action?.payload?.ongoingtargetStartDate),
        (state.ongoingtargetEndDate = action?.payload?.ongoingtargetEndDate),
        (state.ongoingstatus = action?.payload?.ongoingstatus),
        (state.ongoingalert = action?.payload?.ongoingalert),
        (state.ongoingbookmark = action?.payload?.ongoingbookmark),
        (state.ongoingteam = action?.payload?.ongoingteam),
        (state.ongoinganalyst = action?.payload?.ongoinganalyst),
        (state.ongoingmanager = action?.payload?.ongoingmanager),
        (state.ongoinghorizontalTeam = action?.payload?.ongoinghorizontalTeam),
        (state.ongoingchannel = action?.payload?.ongoingchannel),
        (state.ongoingproduct = action?.payload?.ongoingproduct),
        (state.ongoingtransaction_type =
          action?.payload?.ongoingtransaction_type),
        (state.ongoing_country= action?.payload?.ongoing_country),
        (state.ongoing_industry= action?.payload.ongoing_industry),
        (state.ongoing_occupation= action?.payload?.ongoing_occupation),
        (state.ongoing_onboarding_type= action?.payload?.ongoing_onboarding_type),
        (state.ongoing_transaction_product_type= action?.payload?.ongoing_transaction_product_type),
        (state.ongoing_party= action?.payload?.ongoing_party)
    },
    commonOngoingScreenTypeData: (state, action) => {
      (state.common_ongoingsearchId = action?.payload?.common_ongoingsearchId),
        (state.common_ongoingsearchType = action?.payload?.common_ongoingsearchType),
        (state.common_ongoingalertListType = action?.payload?.common_ongoingalertListType),
        (state.common_ongoingalerted = action?.payload?.common_ongoingalerted),
        (state.common_ongoingtargetStartDate =
          action?.payload?.common_ongoingtargetStartDate),
        (state.common_ongoingtargetEndDate = action?.payload?.common_ongoingtargetEndDate),
        (state.common_ongoingstatus = action?.payload?.common_ongoingstatus),
        (state.common_ongoingalert = action?.payload?.common_ongoingalert),
        (state.common_ongoingbookmark = action?.payload?.common_ongoingbookmark),
        (state.common_ongoingteam = action?.payload?.common_ongoingteam),
        (state.common_ongoinganalyst = action?.payload?.common_ongoinganalyst),
        (state.common_ongoingmanager = action?.payload?.common_ongoingmanager),
        (state.common_ongoinghorizontalTeam = action?.payload?.common_ongoinghorizontalTeam),
        (state.common_ongoingchannel = action?.payload?.common_ongoingchannel),
        (state.common_ongoingproduct = action?.payload?.common_ongoingproduct),
        (state.common_ongoingtransaction_type =
          action?.payload?.common_ongoingtransaction_type);
    },
    ongoingRemoveScreenTypeData: (state) => {
      (state.ongoingsearchId = ''),
        (state.ongoingsearchType = ''),
        (state.ongoingalertListType = []),
        (state.ongoingalerted = ''),
        (state.ongoingtargetStartDate = null),
        (state.ongoingtargetEndDate = null),
        (state.ongoingstatus = ''),
        (state.ongoingalert = 20),
        (state.ongoingbookmark = ''),
        (state.ongoingteam = ''),
        (state.ongoinganalyst = ''),
        (state.ongoinghorizontalTeam = ''),
        (state.ongoingmanager = ''),
        (state.ongoingchannel = ''),
        (state.ongoingproduct = ''),
        (state.ongoingtransaction_type = '');
        (state.ongoing_country= ''),
        (state.ongoing_industry= ''),
        (state.ongoing_occupation= ''),
        (state.ongoing_onboarding_type= ''),
        (state.ongoing_transaction_product_type= ''),
        (state.ongoing_party= '')
    },
    commonOngoingRemoveScreenTypeData: (state) => {
      (state.common_ongoingsearchId = ''),
        (state.common_ongoingsearchType = ''),
        (state.common_ongoingalertListType = []),
        (state.common_ongoingalerted = ''),
        (state.common_ongoingtargetStartDate = null),
        (state.common_ongoingtargetEndDate = null),
        (state.common_ongoingstatus = ''),
        (state.common_ongoingalert = 20),
        (state.common_ongoingbookmark = ''),
        (state.common_ongoingteam = ''),
        (state.common_ongoinganalyst = ''),
        (state.common_ongoinghorizontalTeam = ''),
        (state.common_ongoingmanager = ''),
        (state.common_ongoingchannel = ''),
        (state.common_ongoingproduct = ''),
        (state.common_ongoingtransaction_type = '');
    },
  },
});
export const {
  ongoingMonitoringTableView,
  initialScreeningTableView,
  screenTypeData,
  removeScreenTypeData,
  ongoingScreenTypeData,
  ongoingRemoveScreenTypeData,
  setInitialPageIndexInitialScreening,
  setInitialPageSizeInitialScreening,
  resetInitialPageSizeInitialScreening,
  resetInitialPageIndexInitialScreening,
  setInitialPageIndexOngoingMonitoring,
  setInitialPageSizeOngoingMonitoring,
  resetInitialPageSizeOngoingMonitoring,
  resetInitialPageIndexOngoingMonitoring,
  setDownloadReportFilters,
  removeDownloadReportFilters,
  setHistoryFilters,
  setDownloadStatusFilters,
  setRiskRatingDashboardFilters,
  setExclusionListFilter,
  setAddToExclusionListFilter,
  setDashboardFilters,
  commonScreenTypeData,
  commonOngoingScreenTypeData,
  commonRemoveScreenTypeData,
  commonOngoingRemoveScreenTypeData
} = commonSlice.actions;
export default commonSlice.reducer;
