/**
 * Classification': '//SecureWorks/Internal Use
 * Copyright © 2020 SecureWorks, Inc. All rights reserved.
 */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Theme } from './index';
import { selectActiveTheme } from '../state/slices/appSlice';
/**
 * Returns theme based on user preferences.
 *
 * @returns {Object} Current theme
 */
const useCurrentTheme = () => {
  const themeValue = useSelector(selectActiveTheme);
  return useMemo(
    () => (themeValue === 'dark-theme' ? Theme.Dark : Theme.Light),
    [themeValue]
  );
};

export default useCurrentTheme;
