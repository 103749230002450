import { combineReducers } from 'redux';
import commonSlice from '../slices/commonSlice';
import appSlice from '../slices/appSlice';

/**
 * @function reducer  Root reducer.
 * @description Redux root reducer.
 * @param {object} state - Current state.
 * @param {string} name - Name of the action.
 * @param {Function} reducer - Reducer function for the action.
 * @returns {Object} - Root reducer.
 */

const reducer = combineReducers({
  app: appSlice,
  common: commonSlice,
});

export default reducer;
