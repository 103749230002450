// @ts-nocheck
import { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';
import useInitiate from './useInitiate';
import useRiskRating from './useRiskRating';

/**
 * @function useReferrals
 * @description a custom hook which handles APIs related to pending and completed referrals screen
 * @returns {object} object
 */
export default function useReferrals() {
  const { getRiskParameters, industry, product, occupation } = useInitiate();
  const {
    fetchRiskList,
    calculateRiskRating,
    riskList,
    riskValue,
    riskResult,
    setRiskList,
    setRiskValue,
    systemRisk,
    setSystemRisk,
  } = useRiskRating();
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sanctionsList, setSanctionsList] = useState([]);
  const [pepList, setPepList] = useState([]);
  const [amsList, setAmsList] = useState([]);
  const [referralData, setReferralData] = useState([]);
  const [legalList, setLegalList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [legalDocument, setLegalDocument] = useState([]);
  const [fetchUsersPageCount, setFetchUsersPageCount] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [disabled, setdisabled] = useState(true);
  const [cardDetails, setCardDetails] = useState({});
  const [analystsList, setAnalystsList] = useState([]);
  const [referralList, setReferralList] = useState([]);
  const fetchIdRef = useRef(0);
  const { user } = useSelector(selectAuth);

  /**
   * @function resetAllList
   * @description A function to reset all lists
   * @returns {undefined}
   * @example resetAllList()
   */
  const resetAllList = () => {
    setSanctionsList([]);
    setPepList([]);
    setAmsList([]);
    setLegalList([]);
    setCorporateList([]);
  };
  /**
   * @function fetchUserList
   * @description A function to fetch User List
   * @returns {Promise} returns an axios promise
   */
  const fetchUserList = () => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.INITIAL_SCREENING_ALL_ANALYST)
      .then((response) => {
        setAnalystsList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchReferralsList
   * @description A function to fetch Referrals List
   * @returns {Promise} returns an axios promise
   */
  const fetchReferralsList = () => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.INITIAL_SCREENING_REFERRALS)
      .then((response) => {
        setReferralList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  /**
   * @function fetchUsers
   * @description A function to implement pagination
   * @returns {Promise} returns an axios promise
   */
  const fetchUsers = useCallback(
    ({ pageSize, pageIndex, query, orderBy, status, days, checkerLevel }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      fetchIdRef.current += 1;
      const fetchId = fetchIdRef.current;

      // Your server could send back total page count.
      setIsLoading(true);
      axios
        .get(API_ENDPOINTS.REFERRALS_ALERT_LIST, {
          params: {
            page: pageIndex + 1,
            screen: 'initial',
            ...(pageSize ? { pagesize: pageSize } : {}),
            query: query?.trim(),
            orderBy,
            checkerLevel,
            status,
            days,
          },
          loader: !query?.length,
        })
        .then(
          (response) => {
            setIsLoading(false);
            if (fetchId === fetchIdRef.current) {
              setUserList(response.data);
              setTotalUsers(response.headers['x-total-count']);
              setFetchUsersPageCount(
                Math.ceil(response.headers['x-total-count'] / pageSize)
              );
            }
          },
          (error) => {
            setIsLoading(false);
            throw error;
          }
        );
    },
    []
  );
  /**
   * @function fetchCompletedUsers
   * @description A function to implement pagination
   * @returns {Promise} returns an axios promise
   */
  const fetchCompletedUsers = useCallback(
    ({ pageSize, pageIndex, query, orderBy, status, days, checkerLevel }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      fetchIdRef.current += 1;
      const fetchId = fetchIdRef.current;

      // Your server could send back total page count.
      setIsLoading(true);
      axios
        .get(API_ENDPOINTS.REFERRALS_ALERT_LIST, {
          params: {
            completed: true,
            page: pageIndex + 1,
            screen: 'initial',
            ...(pageSize ? { pagesize: pageSize } : {}),
            query: query?.trim(),
            orderBy,
            checkerLevel,
            status,
            days,
          },
          loader: !query?.length,
        })
        .then(
          (response) => {
            setIsLoading(false);
            if (fetchId === fetchIdRef.current) {
              setUserList(response.data);
              setTotalUsers(response.headers['x-total-count']);
              setFetchUsersPageCount(
                Math.ceil(response.headers['x-total-count'] / pageSize)
              );
            }
          },
          () => {
            setIsLoading(false);
          }
        );
    },
    []
  );
  /**
   * @function fetchCardData
   * @description A function to fetch Card Data
   * @param {object} queryParams - query Parameters for fetching card data
   * @returns {Promise} returns an axios promise
   * @example fetchCardData(queryParams)
   */
  const fetchCardData = (queryParams) => {
    let screenType = queryParams?.screen_type;
    if (screenType === 'i') {
      screenType = 'initial';
    } else {
      screenType = 'ongoing';
    }
    setIsLoading(true);
    resetAllList();
    return axios
      .get(
        `${API_ENDPOINTS.REFFERRAL_CARD_DETAIL}${screenType}/${queryParams.search_id}/`
      )
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setCardDetails(response?.data);
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchRefferalData
   * @description A function to fetch Refferal Data
   * @param {object} queryParams - query Parameters for fetching refferal data
   * @returns {promise} AxiosPromise
   * @example  fetchRefferalData(queryParams)
   */
  const fetchRefferalData = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(`${API_ENDPOINTS.REFFERAL_DATA}/${queryParams.search_id}/`)
      .then(
        (response) => {
          setReferralData(response.data);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function updateUser
   * @description A function to update User details
   * @param {object} data - user's data value
   * @returns {Promise} returns an axios promise
   * @example updateUser(data)
   */
  const updateUser = (data) => {
    setIsLoading(true);
    axios
      .patch(`${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${data.id}/`, data)
      .then(
        (response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setdisabled(!disabled);
            enqueueSnackbar(response.data.Msg, {
              variant: 'success',
            });
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchSanctionsList
   * @description A function to fetch Sanctions List
   * @param {object} queryParams - query Parameters for fetching sanctions list data
   * @returns {Promise} returns an axios promise
   * @example fetchSanctionsList(queryParams)
   */
  const fetchSanctionsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.REFFERRAL_CARD_DETAIL}${queryParams?.screen_type}/${queryParams.id}/sanction`
      )
      .then((response) => {
        setSanctionsList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchPepList
   * @description A function to fetch Pep List
   * @param {object} queryParams - query Parameters for fetching pep list data
   * @returns {Promise} returns an axios promise
   * @example fetchPepList(queryParams)
   */
  const fetchPepList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.REFFERRAL_CARD_DETAIL}${queryParams?.screen_type}/${queryParams.id}/pep`
      )
      .then((response) => {
        setIsLoading(false);
        setPepList([...response.data]);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchAmsList
   * @description A function to fetch Ams List
   * @param {object} queryParams - query Parameters for fetching ams list data
   * @returns {Promise} returns an axios promise
   * @example fetchAmsList(queryParams)
   */
  const fetchAmsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.REFFERRAL_CARD_DETAIL}${queryParams?.screen_type}/${queryParams.id}/ams`
      )
      .then((response) => {
        setAmsList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchLegalList
   * @description A function to fetch legal List
   * @param {object} queryParams - query Parameters for fetching legal list data
   * @returns {Promise} returns an axios promise
   * @example fetchLegalList(queryParams)
   */
  const fetchLegalList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.REFFERRAL_CARD_DETAIL}${queryParams?.screen_type}/${queryParams.id}/legal`
      )
      .then((response) => {
        setLegalList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchCorporateList
   * @description A function to fetch corporate List
   * @param {object} queryParams - query Parameters for fetching corporate list data
   * @returns {Promise} returns an axios promise
   * @example fetchCorporateList(queryParams)
   */
  const fetchCorporateList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.REFFERRAL_CARD_DETAIL}${queryParams?.screen_type}/${queryParams.id}/corporate`
      )
      .then((response) => {
        setCorporateList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchLegalDocument
   * @description A function to fetch legal document
   * @param {object} queryParams - query Parameters for fetching legal document
   * @returns {Promise} returns an axios promise
   * @example fetchLegalDocument(queryParams)
   */
  const fetchLegalDocument = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_SUMMARY_LEGAL_DOCUMENT}${queryParams.tid}`
      )
      .then((response) => {
        setIsLoading(false);
        setLegalDocument(response?.data?.legal_documents);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function updateListsRow
   * @description A function to update Lists Row
   * @param {number} index - value
   * @param {string} value - value of list type
   * @param {string} listType - list type value
   * @returns {Promise} returns an axios promise
   * @example updateListsRow({index, value}, listType)
   */
  const updateListsRow = ({ index, value }, listType) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result[index].status = value;
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result[index].status = value;
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'risk':
        setRiskList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].classification = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };
  /**
   * @function updateListsCommentRow
   * @description A function for updating comments from Lists Row
   * @param {number} index - value
   * @param {string} value - value of list type
   * @param {string} listType - list type value
   * @returns {Promise} returns an axios promise
   * @example updateListsCommentRow({index, value}, listType)
   */
  const updateListsCommentRow = ({ index, value }, listType) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result[index].comments = value;
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result[index].comments = value;
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };
  
  function getObjectIndexById(array, id) {
    return array?.findIndex((obj) => obj?.id === id);
  }
  const updateAttachmentInCommentRow = (
    fileDetails,
    URL,
    listType,
    operationType = 'add'
  ) => {
    switch (listType) {
      case 'sanctionList':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          const index = getObjectIndexById(
            sanctionsList?.sanction_result,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList.sanction_result[index].attached_file = fileDetails;
            updatedList.sanction_result[index].attached_file_url = URL;
          } else {
            updatedList.sanction_result[index].attached_file = '';
            updatedList.sanction_result[index].attached_file_url = URL;
          }

          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          const index = getObjectIndexById(
            sanctionsList?.sanction_country_result,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList.sanction_country_result[index].attached_file =
              fileDetails;
            updatedList.sanction_country_result[index].attached_file_url = URL;
          } else {
            updatedList.sanction_country_result[index].attached_file = '';
            updatedList.sanction_country_result[index].attached_file_url = URL;
          }

          return { ...updatedList };
        });
        break;
      case 'pepList':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            pepList,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }

          return [...updatedList];
        });
        break;
      case 'amsList':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            amsList,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...prevState];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            prevState,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            prevState,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...updatedList];
        });
        break;
      default:
    }
  };
  /**
   * @function submitSummaryVerdict
   * @description A function to submit Summary Verdict
   * @param {object} data - submit verdict data
   * @returns {Promise} returns an axios promise
   * @example submitSummaryVerdict(data)
   */
  const submitSummaryVerdict = (data) => {
    setIsLoading(true);
    const payload = {
      remarks: data.remarks,
      status: (data.verdict?.toLowerCase())[0] === 'a' ? 'a' : 'r',
      results: {
        ams_result: data.ams_result,
        pep_result: data.pep_result,
        sanction_result: data.sanction_result,
        sanction_country_result: data.sanction_country_result,
        legal_result: data.legal_result,
        corporate_result: data.corporate_result,
      },
      attachment: data?.attachment,
    };
    return axios
      .post(
        `${API_ENDPOINTS.INITIAL_SCREENING_SUMMARY_VERDICT}${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Verdict submitted successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function submitRequestReferral
   * @description A function to submit Request Referral
   * @param {object} data - submit requested referrals data
   * @returns {Promise} returns an axios promise
   * @example submitRequestReferral(data)
   */
  const submitRequestReferral = (data) => {
    setIsLoading(true);
    const payload = {
      description: data.remarks,
      requested_to: data.analystName,
      attachment: data?.attachment,
      sanctionsList: data.sanctionsList,
      pepList: data.pepList,
      amsList: data.amsList,
      corporateList: data.corporateList,
      legalList: data.legalList,
    };
    return axios
      .post(
        `${API_ENDPOINTS.REFERRALS_SUBMIT_REFERRALS}${data.screen_type}/${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Refer sent successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function submitReferral
   * @description A function submit Referral
   * @param {object} data - submit referral data
   * @returns {Promise} returns an axios promise
   * @example submitReferral(data)
   */
  const submitReferral = (data) => {
    setIsLoading(true);
    const payload = {
      comments: data.remarks,
      // results: {
      sanction_result: data.sanction_result,
      pep_result: data.pep_result,
      ams_result: data.ams_result,
      corporate_result: data.corporate_result,
      legal_result: data.legal_result,
      sanction_country_result: data?.sanction_country_result,
      risk_rating_result: data?.risk_rating_result,
      // },
      attachment: data?.attachment,
    };
    return axios
      .post(`${API_ENDPOINTS.REFERRALS_SUBMIT_REFERRALS}${data.id}/`, payload)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Referral submitted successfully', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  const uploadAttachedFiles = (payload) => {
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.GET_BULK_PRESIGNED_URL, payload)
      .then((res) => {
        setIsLoading(false);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteFile = ({ id, row, type }) => {
    const data = { filename: `${id}*${row?.attached_file}` };
    return axios
      .delete(`${API_ENDPOINTS.DELETE_FILE}`, { params: data })
      .then((res) => {
        updateAttachmentInCommentRow(
          `${id}*${row?.attached_file}`,
          '',
          type,
          'delete'
        );
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.error, {
          variant: 'error',
        });
        throw error;
      });
  };
  const uploadFileOns3 = (uploadUrl, file) => {
    return axios
      .put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
  return {
    user,
    fetchUsers,
    fetchCompletedUsers,
    userList,
    setUserList,
    updateUser,
    fetchSanctionsList,
    fetchPepList,
    fetchAmsList,
    fetchLegalList,
    fetchCorporateList,
    sanctionsList,
    pepList,
    amsList,
    legalList,
    corporateList,
    legalDocument,
    fetchLegalDocument,
    updateListsRow,
    updateListsCommentRow,
    isLoading,
    submitSummaryVerdict,
    fetchUsersPageCount,
    totalUsers,
    disabled,
    setdisabled,
    fetchCardData,
    cardDetails,
    setCardDetails,
    analystsList,
    fetchReferralsList,
    submitReferral,
    submitRequestReferral,
    referralList,
    fetchRefferalData,
    referralData,
    riskList,
    riskValue,
    riskResult,
    fetchRiskList,
    calculateRiskRating,
    getRiskParameters,
    industry,
    product,
    occupation,
    setRiskValue,
    setRiskList,
    systemRisk,
    setSystemRisk,
    uploadAttachedFiles,
    updateAttachmentInCommentRow,
    deleteFile,
    uploadFileOns3,
  };
}
