export const API_ENDPOINTS = {
  DJANGO_SERVER_ADDRESS: process.env.REACT_APP_SAMS_BASE_URL,
  DJANGO_FAST_SERVER_ADDRESS: process.env.REACT_APP_SAMS_BASE_FAST_URL,
  LOGIN: '/auth/login/',
  LOGOUT: '/auth/logout/',
  OTP: '/auth/verify/otp/',
  PANEL_INFO: '/auth/panel-info/',
  REFRESH_TOKEN: 'auth/token-refresh/',
  GET_USER_DATA: '/auth/userdata/',
  RESET_PASSWORD: '/auth/reset-password/',
  CHANGE_PASSWORD: '/auth/change-password/',
  PROFILE_UPDATE: '/auth/userdata/',
  CALCULATE_RISK_RATING: 'risk-rating/calculate-risk-rating/',
  RISK_PARAMETERS: 'risk-rating/get-parameters/',
  UPDATE_RISK_RATING_FIELDS: '/searches/update-risk-rating-field/',
  ADMIN_DASHBOARD_COSTING: '/costing/org-list/',
  ADMIN_DASHBOARD_SEARCH_HISTORY: '/costing/search-history/',
  ADMIN_DASHBOARD_ORGANISATION_LIST: '/sams-management/organisations/',
  ADMIN_DASHBOARD_ORGANISATION_GROUPS: '/sams-management/org-groups/',
  ADMIN_DASHBOARD_PERMISSION_GROUPS: '/sams-management/services/',
  ADMIN_DASHBOARD_TREE_DATA: 'sams-management/org-sanction-config/',
  ADMIN_DASHBOARD_USER: '/team-management/analysts/',
  ADMIN_DASHBOARD_MANAGER: '/sams-management/create-analyst/',
  ADMIN_DASHBOARD_SANCTION_CONFIGURATION:
    '/sams-management/org-sanction-config/',
  ADMIN_BAG_OF_WORDS: '/sams-management/admin-bag-of-words/',
  ADMIN_TRUSTED_SOURCES: '/sams-management/admin-trusted-sources/',

  CLIENT_COSTING: '/review/finance-dashboard/',
  CLIENT_PERMISSION: '/auth/org-permissions/',

  ACCESS_MANAGEMENT: '/auth/register/data',
  ACCESS_MANAGEMENT_POST: '/auth/register/',
  ACCESS_MANAGEMENT_TEAM: '/team-management/teams/',
  ACCESS_MANAGEMENT_HORIZONTAL_TEAM: '/team-management/teams/horizontal',
  ACCESS_MANAGEMENT_USER: '/team-management/analysts/',
  ACCESS_MANAGEMENT_USER_ADMIN: '/team-management/create-manager/',
  ACCESS_MANAGEMENT_GROUP: '/team-management/groups/',
  ACCESS_MANAGEMENT_PERMISSION: '/team-management/permissions/',

  DOWNLOAD: '/auth/download/',
  DOWNLOAD_REPORT: '/review/report/',
  DOWNLOAD_STATUS: '/review/status/',
  DOWNLOAD_STATUS_EXCEL: '/review/status/',
  DOWNLOAD_ANALYST: '/auth/analyst/',

  DASHBOARD_STATS: '/review/dashboard/',
  REVIEW_HISTORY: '/review/history',
  AUDIT_TRAIL_HISTORY: '/auth/get-audit-logs',
  DASHBOARD_STACK_CHART_DRILLDOWN: '/review/stack-chart/',
  DASHBOARD_LINE_CHART_DRILLDOWN: '/review/target-missed-chart/',
  DASHBOARD_USER_HISTORY: '/review/get-completed/',
  DASHBOARD_USER_TYPE: '/team-management/analysts/',
  DASHBOARD_BATCH_INFO: '/review/batch-info/',
  DASHBOARD_AVAILABLE_SEARCHES: '/review/available-searches/',

  RULE_ENGINE_SEARCH_RULE_UPDATE: '/auth/searchrules/',
  RULE_ENGINE_SANCTION_SOURCE: '/delta-source/',
  RULE_ENGINE_DELTA_CHANGE: '/delta/',
  RULE_ENGINE_FILTER_EXCLUSION_LIST: '/searches/filter-exclusion/',
  RULE_ENGINE_EXCLUSION_LIST: '/searches/get-exclusion/',
  RULE_ENGINE_BATCH_ID: '/searches/get-batch-id/',
  RULE_ENGINE_ADD_EXCLUSION_LIST: '/searches/create-new-batch/',
  RULE_ENGINE_FINAL_ADD_EXCLUSION_LIST: '/searches/add-exclusion/',
  RULE_ENGINE_REMOVE_EXCLUSION_LIST: '/searches/remove-exclusion/',
  MOVE_IS_TO_OM: '/searches/move-is-to-om/',
  DOWNLOAD_EXCLUSION_FILES: '/searches/download-exclusion-file/',
  RULE_ENGINE_CARD_DATA: '/get-card-data/',
  RULE_ENGINE_BAG_OF_WORDS: '/auth/bag-of-words/',
  RULE_ENGINE_TRUSTED_SOURCES: '/auth/trusted-sources/',
  RULE_ENGINE_SUPPRESSION_LOGIC: '/auth/org-rules/',
  RULE_ENGINE_SANCTION_LIST: '/auth/source-list/',
  RULE_ENGINE_REMAINING_SANCTION_LIST: '/auth/remaning-list/',
  RULE_ENGINE_COMMON: '/auth/common-rules/',
  RULE_ENGINE_DELTA_CHANGE_FREQUENCY: '/searches/get-frequency/',
  RULE_ENGINE_GET_COUNTRY: '/auth/get-country/',
  RULE_ENGINE_RISK_RATING_PARAMETERS: '/risk-rating/parameters/',
  RULE_ENGINE_RISK_RATING_PARAMETER_CLASSIFICATION:
    '/risk-rating/parameters-classification-save/',
  RULE_ENGINE_GET_PARAMETER_LIST: 'risk-rating/get-parameters/',
  RULE_ENGINE_AMS_CRAWLER: '/searches/crawler-ams/',
  RULE_ENGINE_BULK_AMS_CRAWLER: '/searches/bulk-ams/',
  SUPPRESSION_LOGIC_REINITIATE_SEARCHES: '/review/reinitiate-searches/',
  DOWNLOAD_RULE_ENGINE: '/auth/download-rule-engine/',

  Sanction_Configuration_SANCTION_SOURCE: '/auth/source-update/',
  SANCTION_CONFIGURATION_COMMON_RULE: '/auth/common-rule-engine/',
  Sanction_Configuration_TREE_DATA: '/auth/sanction-list/',
  SANCTION_CONFIGURATION_PRIVATE_LIST_PROCESS: '/auth/process-file/',
  SANCTION_CONFIGURATION_PRIVATE_LIST: '/auth/show-private-lists/',
  SANCTION_CONFIGURATION_DELETE_LIST: '/auth/delete-private-lists/',
  PEP_CONFIGURATION: '/auth/pep-country-list/',

  REFERRALS_ALERT_LIST: '/review/referrals/',
  REFERRALS_ALERT_LIST_COMPLETED: '/review/referrals/?completed=true',
  REFERRALS_SUBMIT_REFERRALS: '/review/submit-referral/',
  REFFERRAL_CARD_DETAIL: '/review/search-list/',
  REFFERAL_DATA: '/review/referral-data',

  INITIAL_SCREENING_ALERT_LIST: '/review/search-list/initial/',
  INITIAL_SCREENING_CARD_DETAILS: '/review/search-list/initial/',
  INITIAL_SCREENING_SUMMARY_LEGAL_DOCUMENT: '/review/legal-doc/',
  INITIAL_SCREENING_SUMMARY_VERDICT: '/review/verdict/initial/',
  INITIAL_SCREENING_USER_REFERRAL: '/review/request-referral/',
  INITIAL_SCREENING_ALL_ANALYST: '/review/get-all-analysts/',
  INITIAL_SCREENING_REQUEST_ANALYST: '/review/request-referral/initial/',
  INITIAL_SCREENING_REFERRALS: '/review/referrals/',
  INITIAL_SCREENING_SUBMIT_REFERRALS: '/review/submit-referral/',

  INITIAL_ONGOING_COMMON_SEARCHES_ASSIGN:
    '/team-management/teams/horizontal/assign-searches',

  ONGOING_SCREENING_ALERT_LIST: '/review/search-list/ongoing/',
  ONGOING_SCREENING_CARD_DETAILS: '/review/search-list/ongoing/',
  ONGOING_SCREENING_SUMMARY_VERDICT: '/review/verdict/ongoing/',
  ONGOING_SCREENING_SUMMARY_LEGAL_DOCUMENT: '/review/legal-doc/',
  ONGOING_SCREENING_SUMMARY_CUSTOMER_INFO: '/review/customer-info/',
  ONGOING_SCREENING_USER_REFERRAL: '/review/request-referral/',
  ONGOING_SCREENING_ALL_ANALYST: '/review/get-all-analysts/',
  ONGOING_SCREENING_REQUEST_ANALYST: '/review/request-referral/ongoing/',
  ONGOING_SCREENING_REFERRALS: '/review/referrals/',
  ONGOING_SCREENING_SUBMIT_REFERRALS: '/review/submit-referral/',

  INITIAL_ONGOING_BOOKMARK: '/review/bookmark/',

  INITIATE_SCREEN_BAG_OF_WORDS: '/auth/bag-of-words/',
  INITIATE_SCREEN_TREESOURCE_DATA: '/delta-source/',
  INITIATE_SCREEN_BULK_FILE_UPLOAD: '/import-file/',
  INITIATE_SCREEN_BULK_FILE_LIST: '/file-data/',
  INITIATE_SCREEN_BULK_INITIAL_COUNT: '/intial/get/count/',
  INITIATE_SCREEN_BULK_ONGOING_COUNT: '/intial/get/count/',
  INITIATE_SCREEN_BULK_ASSIGN: '/searches/initiate/bulk-assign/',
  INITIATE_SCREEN_BULK_ONGOING: '/assign/ongoing/bulk/',
  INITIATE_SCREEN_BULK_ALERT_COUNT: '/review/batch-progress/',
  INITIATE_SCREEN_FINAL_BULK_CALL_INITIAL: '/searches/initiate/bulk/',
  INITIATE_SCREEN_FINAL_BULK_CALL_ONGOING: '/searches/initiate/bulk/',
  INITIATE_SCREEN_INITIAL_INDI: '/searches/initiate/',
  INITIATE_SCREEN_ONGOING_INDI: '/initiate/ongoing/search/',
  INITIATE_SCREEN_EMAIL: '/search-email/',
  INITIATE_SCREEN_TEAM_DATA: '/team-management/teams/',

  INITIATE_SCREEN_MANUAL_ASSIGN: '/searches/manual-assign/',
  INITIATE_SCREEN_MANUAL_ASSIGN_SEARCHES: '/searches/assign-search/',
  INITIATE_SCREEN_GET_BATCHID: '/getBatchIds/',

  MULTIPART_UPLOAD_START_UPLOAD: '/upload/start-upload/',
  MULTIPART_UPLOAD_GET_PRESIGNED_URL: '/upload/get-presigned-url/',
  MULTIPART_UPLOAD_COMPLETE: '/upload/complete-upload/ ',
  MULTIPART_UPLOAD_DELETE: '/upload/delete-upload/ ',

  DEDUPLICATE_INFORMATION: '/review/deduplicate',
  DEDUPLICATION_PARAMETER: '/review/get_deduplication_parameter',
  RISK_RATING_DASHBOARD: '/risk-rating/risk-rating-dashboad/',
  RISK_RATING_DASHBOARD_CHART: '/risk-rating/over-all-chart/',
  RISK_RATING_DASHBOARD_CHART_BY_PARAMETER:
    '/risk-rating/parameter-count-chart/',
  RISK_RATING_DASHBOARD_MIGRATION_MATRIX:
    '/risk-rating/migration-metrix-chart/',
  RISK_RATING_REKYC: '/risk-rating/re-kyc/',

  MULTIPART_TTR_UPLOAD_START_UPLOAD: '/ttr/start-upload/',
  MULTIPART_TTR_UPLOAD_GET_PRESIGNED_URL: '/ttr/get-presigned-url/',
  MULTIPART_TTR_UPLOAD_COMPLETE: '/ttr/complete-upload/ ',
  TTR_LIST_PROCESS: '/ttr/transform-ttr/',
  MULTIPART_TTR_UPLOAD_DELETE: '/ttr/delete-upload/ ',
  TTR_DOWNLOAD_FILES: '/ttr/fetch-xml-files',
  TTR_BRANCH_INFO: '/ttr/branch-file-info/',

  TRANSACTION_SCREENING_RULE_ENGINE:
    '/transaction-screening/transaction-parameters/',
  CUSTOMIZE_FILTERS_AND_COLUMNS: '/review/customize-filters-and-columns/',

  TRANSACTION_PARAMETERS: '/transaction-screening/transaction-parameters/',
  ALL_RULE_IDS: '/auth/all-rule-ids',
  TRANSACTION_FINAL_CALL: '/aml-searches/v1/transaction-screening/async',
  GET_BULK_PRESIGNED_URL: '/upload/get-bulk-presigned-url/',
  DELETE_FILE: 'upload/file-delete/',
};
