// @ts-nocheck
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  HashRouter,
  useLocation,
  BrowserRouter,
} from 'react-router-dom';
import { useTheme } from '@mui/material';
import UserConfirmationPrompt from '../components/Common/UserConfirmationPrompt';
import { CoreUtils } from '../utils/Utils';

import Login from '../pages/Login';
import Organization from '../pages/Organization';
import Layout from '../pages/Layout';
import PageNotFound from '../pages/PageNotFound';

const key = 'REALM_ID'; // The key you're checking for

const PrivateRoute = ({ component: Component, isLoggedIn, loading }) => (
  <Route
    render={(props) => {
      if (
        !isLoggedIn &&
        process.env.REACT_APP_KEYCLOAK_LOGIN === 'true' &&
        localStorage.getItem(key) !== null &&
        loading
      ) {
        return <h5>Loading...</h5>;
      }
      if (!isLoggedIn) {
        if (
          process.env.REACT_APP_KEYCLOAK_LOGIN === 'true' &&
          localStorage.getItem(key) === null
        ) {
          return <Redirect to="/org" />;
        }
        return <Redirect to="/login" />;
      }
      return <Component {...props} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export const AppRoutes = ({ loading, isLoggedIn }) => {
  const { userConfirmationPrompt } = UserConfirmationPrompt(useTheme());

  return (
    <BrowserRouter>
      <React.Suspense fallback="">
        <Switch>
          {!isLoggedIn && process.env.REACT_APP_KEYCLOAK_LOGIN === 'true' && (
            <Route path="/org" component={Organization} />
          )}
          {!isLoggedIn && process.env.REACT_APP_KEYCLOAK_LOGIN !== 'true' && (
            <Route path="/login" component={Login} />
          )}
          {/* <Route path="/org" component={Organization} />
          <Route path="/login" component={Login} /> */}

          <PrivateRoute
            path="/"
            isLoggedIn={isLoggedIn}
            component={Layout}
            loading={loading}
          />
          <Route
            path="*"
            name="Not Found"
            render={(props) => <PageNotFound {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};
