export const sanctionsListSort = (
  sortBy,
  setItems,
  items,
  userHistorySummary
) => {
  let dummy = items;
  // Sort By name here
  if (sortBy === 'samsScore') {
    dummy = items.sort(function (a, b) {
      const nameA = a.sams_score.overall_score; // ignore upper and lowercase
      const nameB = b.sams_score.overall_score; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  } else if (sortBy === 'nameScore') {
    // Write sort by date here
    dummy = items.sort(function (a, b) {
      const nameA = a.sams_score.name_match_score; // ignore upper and lowercase
      const nameB = b.sams_score.name_match_score; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  } else if (sortBy === 'dobScore') {
    dummy = items.sort(function (a, b) {
      const nameA = a.sams_score.dob_match_score; // ignore upper and lowercase
      const nameB = b.sams_score.dob_match_score; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  } else if (sortBy === 'pepReset') {
    dummy = userHistorySummary?.pepList;
  } else {
    dummy = userHistorySummary?.sanctionsList;
  }

  setItems([...dummy]);
};

export const amsListSort = (sortBy, setItems, items, userHistorySummary) => {
  let dummy = items;
  // Sort By name here
  if (sortBy === 'amsSamsScore') {
    dummy = items.sort(function (a, b) {
      const nameA = a.overall_score; // ignore upper and lowercase
      const nameB = b.overall_score; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  } else if (sortBy === 'amsNameScore') {
    // Write sort by date here
    dummy = items.sort(function (a, b) {
      const nameA = a.name_matching_score; // ignore upper and lowercase
      const nameB = b.name_matching_score; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  } else if (sortBy === 'sentimentScore') {
    dummy = items.sort(function (a, b) {
      const nameA = a.sentiment_score.score; // ignore upper and lowercase
      const nameB = b.sentiment_score.score; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  } else if (sortBy === 'amsSearchEngine') {
    dummy = items?.sort(function (a, b) {
      const nameA = a?.search_dbname; // ignore upper and lowercase
      const nameB = b?.search_dbname; // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      // names must be equal
      return 0;
    });
    dummy.reverse();
  }
  else {
    dummy = userHistorySummary?.amsList;
  }

  setItems([...dummy]);
};

export const TableSortUtils = {
  sanctionsListSort,
  amsListSort,
};
