import axios from 'axios';
import { API_ENDPOINTS } from '../const/ApiEndpoints';

export default function useBulkFileUpload() {
  /**
   * @function initiateFileUpload
   * @description A function to upload files
   * @returns {Promise} returns an axios promise
   */
  // MULTI PART UPLOAD
  const initiateFileUpload = (fileDetails, successCallback, errorCallback) => {
    axios.post(API_ENDPOINTS.MULTIPART_UPLOAD_START_UPLOAD, fileDetails).then(
      (resp) => {
        if (successCallback) {
          successCallback(resp);
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
  /**
   * @function fetchPresignedUrlForEachChunk
   * @description A function to post url
   * @returns {Promise} returns an axios promise
   */
  const fetchPresignedUrlForEachChunk = (chunkDetails) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_GET_PRESIGNED_URL, chunkDetails)
      .then();

  /**
   * @function onCompleteMultipartUpload
   * @description A function to upload files in multiple parts
   * @returns {Promise} returns an axios promise
   */
  const onCompleteMultipartUpload = (
    fileChunksSummary,
    successCallback,
    errorCallback
  ) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_COMPLETE, {
        ...fileChunksSummary,
      })
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  return {
    onCompleteMultipartUpload,
    fetchPresignedUrlForEachChunk,
    initiateFileUpload,
  };
}
