// @ts-nocheck
/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
// import { store } from './state/';
import { PersistGate } from 'redux-persist/integration/react';
import { init as initApm } from '@elastic/apm-rum';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Slide, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { store, persistor } from './state/store/Store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import packageJson from '../package.json';

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

try {
  // eslint-disable-next-line no-unused-vars
  const apm = initApm({
    serviceName: `${packageJson.name}-${process.env.REACT_APP_ENV}`,

    serverUrl: `${process.env.REACT_APP_ERROR_LOGGER}`,

    // Set service version (required for sourcemap feature)
    serviceVersion: packageJson.version,
    environment: process.env.REACT_APP_ENV,
    active: process.env.NODE_ENV === 'production',
    distributedTracingOrigins: window.location.pathname,
    logLevel: 'warn',
  });
  window.apm = apm;
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Failed to start APM RUM Agent', e);
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionComponent={Slide}
          hideIconVariant={false}
          ref={notistackRef}
          action={(key) => (
            <IconButton
              aria-label="close"
              size="small"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon style={{ fill: '#FFFFFF' }} color="secondary" />
            </IconButton>
          )}
        >
          {/* <BrowserRouter> */}
          <App notistackRef={notistackRef}/>
          {/* </BrowserRouter> */}
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
