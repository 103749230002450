import React from 'react';
import { Button, Grid, Typography, Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  otp: Yup.number()
    .required('Please enter the OTP sent on your registered email ID')
    .positive()
    .integer('OTP must be a number type')
    .test('len', 'Must be exactly 6 digits', (val) => {
      if (val) return val.toString().length === 6;
    })
    .typeError('Invalid number'),
});
/**
 * @function Otp
 * @description a component to render the UI of Otp form
 * @param {function} LoginOtp - function for logging in through the Otp
 * @returns {React.ReactElement} return a component to render the UI of Otp form
 * @example <Otp loginOtp={loginOtp}/>
 */
const Otp = ({ loginOtp }) => {
  const history = useHistory();
  const otpForm = useFormik({
    initialValues: {
      otp: '',
    },
    onSubmit: (values) => {
      loginOtp(values, history);
    },
    validationSchema,
  });

  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sm={9}>
          <Box mb={1}>
            <Typography color="primary" variant="body1" noWrap={true}>
              Please enter the OTP sent on your registered email ID
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <form onSubmit={otpForm.handleSubmit}>
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 2, md: 3 }}
        >
          <Grid item xs={10} sm={9}>
            <TextField
              placeholder="Enter OTP"
              variant="outlined"
              fullWidth
              name="otp"
              inputProps={{
                maxLength: 6,
              }}
              label=""
              InputLabelProps={{ shrink: false }}
              error={Boolean(otpForm.touched.otp && otpForm.errors.otp)}
              helperText={otpForm.touched.otp && otpForm.errors.otp}
              {...otpForm.getFieldProps('otp')}
              type="text"
              id="outlined-basic"
            />
          </Grid>
          <Grid item xs={10} sm={9}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                Submit
              </Button>
              <Box>
                <Typography color="primary" noWrap={true}>
                  Resend OTP?
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Otp;

Otp.propTypes = {
  loginOtp: PropTypes.func.isRequired,
};
