// import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import rootReducer from './RootStore';
// @ts-ignore
import packageJson from '../../../package.json';

const persistConfig = {
  key: 'root',
  version: packageJson.version,
  storage: sessionStorage,
  whitelist: ['common'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * @function store - Create redux store
 * @description - Create redux store with saga middleware
 * @param {object} rootReducer - Root reducer
 * @param {object} middlewares - Middlewares
 * @returns {object} - Redux store
 */
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ];
  },
});
/** run saga watchers */
export const persistor = persistStore(store);
