const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536, xxl: 1920 },
};
const theme = {
  spacing: 5,
  direction: 'ltr',
  breakpoints,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13.125,
    subtitle1: {
      fontWeight: 600,
      letterSpacing: 1,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'capitalize',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {},
        MuiSelect: {
          select: {
            minWidth: 100,
            size: 'small',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 2,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'rgba(0,0,0,0.6)',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 8,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '45px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: 13.125,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#f5fcfb',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '6px',
        },
        head: {
          color: '#fff !important',
          fontWeight: 600,
          padding: '6px 4px !important',
          userSelect: 'none',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '& .MuiCheckbox-root': {
            color: '#fff',
          },
          '& .Mui-checked': {
            color: '#fff !important',
          },
          '&:last-child': {
            borderRadius: '0 4px 0 0',
          },
          '&:first-of-type': {
            borderRadius: '4px 0 0 0',
          },
        },
        sizeSmall: {
          padding: '8px 4px ',
          wordBreak: 'break-word',
          '&:last-child': {
            paddingRight: 4,
            borderRight: 'none',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: '#fff !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
          display: 'block',
          borderBottom: 0,
          '& p': {
            margin: 0,
          },
        },
        caption: {
          userSelect: 'none',
        },
        select: {
          borderRadius: 2,
          paddingX: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: '1rem',
          lineHeight: 0,

          '&$selected': {},
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        indicator: {},
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: 7,
          paddingBottom: 7,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 8,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
            borderRadius: '50% 50% 50% 0',
            backgroundColor: '#0bb7a7',
            transformOrigin: 'bottom left',
            transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
            '&:before': { display: 'none' },
            '&.MuiSlider-valueLabelOpen': {
              transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
            },
            '& > *': {
              transform: 'rotate(45deg)',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 190,
        },
      },
    },
  },
  shape: {},
  transitions: {},
  zIndex: {},
  styledRadio: {
    small: 13,
    standard: 15,
  },
};

export default theme;
