// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import useDashBoard from './useDashboard';

/**
 * @function useRiskRating
 * @description a custom hook which handles APIs related to Risk Rating screen
 * @returns {object} object
 */
export default function useRiskRating() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [riskList, setRiskList] = useState();
  const [systemRisk, setSystemRisk] = useState([]);
  const [riskValue, setRiskValue] = useState();
  const [riskResult, setRiskResult] = useState([]);
  const [riskResponse, setRiskResponse] = useState([]);
  const [riskDasboardData, setRiskDashboardData] = useState([]);
  const [riskRatingChartData, setRiskRatingChartData] = useState([]);
  const { userTypes, analystType } = useDashBoard();
  const [riskRatingChartByParameter, setRiskRatingChartByParameter] = useState(
    []
  );
  const [riskRatingMigrationMatrixData, setRiskRatingMigrationMatrixData] =
    useState([]);

  /**
   * @function fetchRiskList
   * @description A function to fetch risk List
   * @returns {Promise} returns an axios promise
   */
  const fetchRiskList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(`${API_ENDPOINTS.CALCULATE_RISK_RATING}`, {
        params: {
          screen_type: queryParams.screen_type,
          search_id: queryParams.id,
          list_type: 'risk_rating',
        },
      })
      .then((response) => {
        setRiskResponse(response?.data);
        if (queryParams?.search_type === 'i') {
          setSystemRisk([
            ...response?.data?.system_generated_rr_values,
            {
              name: 'sanctions',
              value:
                queryParams?.data?.sanction_count !== 0 ||
                queryParams?.data?.sanction_country_count !== 0
                  ? 'yes'
                  : 'No',
              classification:
                queryParams?.data?.sanction_count !== 0 ||
                queryParams?.data?.sanction_country_count !== 0
                  ? 'high'
                  : 'low',
            },
            {
              name: 'pep',
              value: queryParams?.data?.pep_count !== 0 ? 'yes' : 'No',
              classification:
                queryParams?.data?.pep_count !== 0 ? 'high' : 'low',
            },
            {
              name: 'ams',
              value: queryParams?.data?.ams_count !== 0 ? 'yes' : 'No',
              classification: queryParams?.data?.ams_count ? 'high' : 'low',
            },
          ]);
        } else {
          setSystemRisk([
            ...response?.data?.system_generated_rr_values,
            {
              name: 'sanctions',
              value:
                queryParams?.data?.sanction_count !== 0 ||
                queryParams?.data?.sanction_country_count !== 0
                  ? 'yes'
                  : 'No',
              classification:
                queryParams?.data?.sanction_count !== 0 ||
                queryParams?.data?.sanction_country_count !== 0
                  ? 'high'
                  : 'low',
            },
            {
              name: 'ams',
              value: queryParams?.data?.ams_count !== 0 ? 'yes' : 'No',
              classification:
                queryParams?.data?.ams_count !== 0 ? 'high' : 'low',
            },
          ]);
        }
        if (
          response?.data?.risk_rating_values?.filter(
            (row) =>
              row?.name === 'sanctions' ||
              row?.name === 'pep' ||
              row?.name === 'ams'
          ).length
        ) {
          setRiskList(response?.data?.risk_rating_values);
        } else {
          setRiskList([
            ...response?.data?.risk_rating_values,
            {
              name: 'sanctions',
              value:
                queryParams?.data?.sanction_count !== 0 ||
                queryParams?.data?.sanction_country_count !== 0
                  ? 'yes'
                  : 'No',
              classification:
                queryParams?.data?.sanction_count !== 0 ||
                queryParams?.data?.sanction_country_count !== 0
                  ? 'high'
                  : 'low',
            },
            {
              name: 'pep',
              value: queryParams?.data?.pep_count !== 0 ? 'yes' : 'No',
              classification:
                queryParams?.data?.pep_count !== 0 ? 'high' : 'low',
            },
            {
              name: 'ams',
              value: queryParams?.data?.ams_count !== 0 ? 'yes' : 'No',
              classification: queryParams?.data?.ams_count ? 'high' : 'low',
            },
          ]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };

  /**
   * @function calculateRiskRating
   * @description A function to calculate risk rating
   * @returns {Promise} returns an axios promise
   */
  const calculateRiskRating = (data) => {
    setIsLoading(true);
    axios
      .post(`${API_ENDPOINTS.CALCULATE_RISK_RATING}`, {
        screen_type: data?.screen_type,
        search_id: data?.search_id,
        risk_rating_values: data?.riskList,
        search_type: data?.search_type,
      })
      .then((response) => {
        setIsLoading(false);
        setRiskValue(response?.data?.risk_rating);
        setRiskResult(response?.data?.data);
        enqueueSnackbar(response?.data?.msg, {
          variant: 'success',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };
  const fetchRiskRatingDashboardValue = (data) => {
    const queryParam = {
      ...data,
    };
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RISK_RATING_DASHBOARD, {
        params: queryParam,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRiskDashboardData(response?.data);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  const fetchRiskRatingChartdValue = (data) => {
    const queryParam = {
      screen_type: data?.screen_type,
      search_type: data?.search_type !== '' ? data?.search_type : null,
      start_date: data?.start_date !== '' ? data?.start_date : null,
      end_date: data?.end_date !== '' ? data?.end_date : null,
      analyst_id: data?.analyst ? data?.analyst : null,
      manager_id: data?.manager ? data?.manager : null,
      team_id: data?.team ? data?.team : null,
    };
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RISK_RATING_DASHBOARD_CHART, { params: queryParam })
      .then(
        (response) => {
          setIsLoading(false);
          setRiskRatingChartData(response?.data);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  const fetchRiskRatingChartByParameter = (data) => {
    const queryParam = {
      analyst_id: data?.analyst,
    };
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RISK_RATING_DASHBOARD_CHART_BY_PARAMETER, {
        params: queryParam,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRiskRatingChartByParameter(response?.data);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  const fetchMigrationMatrix = (data) => {
    const queryParam = {
      ...data,
    };
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.RISK_RATING_DASHBOARD_MIGRATION_MATRIX, {
        params: queryParam,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRiskRatingMigrationMatrixData(response?.data);
          return response;
        },

        () => {
          setIsLoading(false);
        }
      );
  };
  return {
    fetchRiskList,
    calculateRiskRating,
    riskList,
    riskValue,
    riskResult,
    setRiskValue,
    setRiskList,
    systemRisk,
    setSystemRisk,
    riskResponse,
    fetchRiskRatingDashboardValue,
    userTypes,
    analystType,
    riskDasboardData,
    riskRatingChartData,
    fetchRiskRatingChartdValue,
    fetchRiskRatingChartByParameter,
    riskRatingChartByParameter,
    fetchMigrationMatrix,
    riskRatingMigrationMatrixData,
    isLoading,
  };
}
