import React from 'react';
import {
  Button,
  Grid,
  Typography,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  accountId: Yup.string().required('Account Id is required'),
  email: Yup.string().email('Invalid email').required('Email Required!'),
});
/**
 * @function ResetPassword
 * @description a component to render the UI of resetting the password form
 * @param {function} resetPassword - function for resetting the password
 * @param {function} setCurrentPage - function for setting the current Page
 * @returns {React.ReactElement} return a component to render the UI of reset password form
 * @example <ResetPassword resetPassword={resetPassword} setCurrentPage={setCurrentPage}/>
 */
const ResetPassword = ({ resetPassword, setCurrentPage }) => {
  const resetForm = useFormik({
    initialValues: {
      accountId: '',
      email: '',
    },
    onSubmit: (values) => {
      resetPassword(values);
    },
    validationSchema,
  });

  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10} sm={9}>
          <Box mb={1}>
            <Typography color="primary" variant="body1" noWrap={true}>
              Please enter Your register account ID and email ID
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 2, md: 3 }}
      >
        <Grid item xs={10} sm={9}>
          <Box mb={3}>
            <TextField
              fullWidth
              autoComplete="organization"
              type="text"
              label=""
              InputLabelProps={{ shrink: false }}
              placeholder="Account Id"
              {...resetForm.getFieldProps('accountId')}
              error={Boolean(
                resetForm.touched.accountId && resetForm.errors.accountId
              )}
              helperText={
                resetForm.touched.accountId && resetForm.errors.accountId
              }
            />
          </Box>
          <TextField
            placeholder="Enter your registered email"
            variant="outlined"
            fullWidth
            name="email"
            label=""
            InputLabelProps={{ shrink: false }}
            error={Boolean(resetForm.touched.email && resetForm.errors.email)}
            helperText={resetForm.touched.email && resetForm.errors.email}
            {...resetForm.getFieldProps('email')}
            type="text"
            id="outlined-basic"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={10} sm={9}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size="large"
              onClick={resetForm.handleSubmit}
            >
              Submit
            </Button>
            <Box>
              <Link style={{ textDecoration: 'none' }} to="/login">
                <Typography
                  onClick={() => setCurrentPage('Login')}
                  color="primary"
                  noWrap={true}
                >
                  Login?
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};
