import { lazy } from 'react';
import lazyWithRetry from '../utils/lazyWithRetry';

const AdminDashboard = lazyWithRetry(() => import('../pages/Admin'));
const Costing = lazyWithRetry(() => import('../pages/Costing'));
const AdminBagOfWords = lazyWithRetry(() => import('../pages/AdminBagOfWords'));
const AdminTrustedSources = lazyWithRetry(
  () => import('../pages/AdminTrustedSources')
);
const AnalystDashboard = lazyWithRetry(() => import('../pages/Dashboard'));
const ManagerDashboard = lazyWithRetry(() => import('../pages/Dashboard'));
const TTRUploadFile = lazyWithRetry(() => import('../pages/TTRReport'));
const DownloadTTRFiles = lazyWithRetry(
  () => import('../pages/DownloadTTRFiles')
);
const RiskRatingDashboard = lazyWithRetry(
  () => import('../pages/RiskRatingDashboard')
);
const CommonRuleEngine = lazyWithRetry(
  () => import('../pages/CommonRuleEngine')
);
const ManagerSetup = lazyWithRetry(() => import('../pages/ManagerSetup'));
const AccessManagement = lazyWithRetry(
  () => import('../pages/AccessManagement')
);
const DownloadsReport = lazyWithRetry(() => import('../pages/Downloads'));
const DownloadStatus = lazyWithRetry(() => import('../pages/DownloadStatus'));
const PendingReferrals = lazyWithRetry(
  () => import('../pages/PendingReferrals')
);
const CompletedReferrals = lazyWithRetry(
  () => import('../pages/CompletedReferrals')
);
const UploadSearches = lazyWithRetry(() => import('../pages/Initiate'));
const AssignSearches = lazyWithRetry(() => import('../pages/AssignSearches'));
const InitialScreening = lazyWithRetry(
  () => import('../pages/InitialScreening')
);
const OngoingScreening = lazyWithRetry(
  () => import('../pages/OngoingScreening')
);
const CommonSeachesOngoingMonitoring = lazyWithRetry(
  () => import('../pages/CommonSeachesOngoingMonitoring')
);
const CommonSeachesInitialScreening = lazyWithRetry(
  () => import('../pages/CommonSeachesInitialScreening')
);
const SanctionsConfiguration = lazyWithRetry(
  () => import('../pages/SanctionsConfiguration')
);
const History = lazyWithRetry(() => import('../pages/History'));

export const ROUTES = [
  {
    name: 'admin',
    path: '/admin-dashboard',
    component: AdminDashboard,
  },
  {
    name: 'costing',
    path: '/costing',
    component: Costing,
  },
  {
    name: 'bagOfWords',
    path: '/bag-of-words',
    component: AdminBagOfWords,
  },
  {
    name: 'trustedSources',
    path: '/trusted-sources',
    component: AdminTrustedSources,
  },
  {
    name: 'analystDashboard',
    path: '/analyst-dashboard',
    component: AnalystDashboard,
  },
  {
    name: 'dashboard',
    path: '/manager-dashboard',
    component: ManagerDashboard,
  },
  {
    name: 'upload',
    path: '/ttr/upload-file',
    component: TTRUploadFile,
  },
  {
    name: 'downloadfiles',
    path: '/ttr/download-xml-files',
    component: DownloadTTRFiles,
  },
  {
    name: 'risk-rating-dashboard',
    path: '/risk-rating-dashboard',
    component: RiskRatingDashboard,
  },
  {
    name: 'common-rule-engine',
    path: '/manager-setup/common-rules',
    component: CommonRuleEngine,
  },
  {
    name: 'multiple-rule-engine',
    path: '/manager-setup/multiple-rules',
    component: ManagerSetup,
  },
  {
    name: 'accessManagement',
    path: '/access-management',
    component: AccessManagement,
  },
  {
    name: 'downloadsReport',
    path: '/download/download-report',
    component: DownloadsReport,
  },
  {
    name: 'downloadsStatus',
    path: '/download/download-status',
    component: DownloadStatus,
  },
  {
    name: 'pendingReferrals',
    path: '/referrals/pending-referrals',
    component: PendingReferrals,
  },
  {
    name: 'completedReferrals',
    path: '/referrals/completed-referrals',
    component: CompletedReferrals,
  },
  {
    name: 'uploadSearches',
    path: '/initiate/upload-searches',
    component: UploadSearches,
  },
  {
    name: 'assignSearches',
    path: '/initiate/assign-searches',
    component: AssignSearches,
  },
  {
    name: 'InitialScreening',
    path: '/initial-screening/my-queue',
    component: InitialScreening,
  },
  {
    name: 'OngoingScreening',
    path: '/ongoing-screening/my-queue',
    component: OngoingScreening,
  },
  {
    name: 'CommonSeachesOngoingMonitoring',
    path: '/ongoing-screening/common-queue',
    component: CommonSeachesOngoingMonitoring,
  },
  {
    name: 'CommonSeachesInitialScreening',
    path: '/initial-screening/common-queue',
    component: CommonSeachesInitialScreening,
  },
  {
    name: 'SanctionsConfiguration',
    path: '/sanctions-configuration',
    component: SanctionsConfiguration,
  },
  {
    name: 'History',
    path: '/history',
    component: History,
  },
];
