import { useDispatch, useSelector } from 'react-redux';
import {
  ongoingMonitoringTableView,
  initialScreeningTableView,
  screenTypeData,
  removeScreenTypeData,
  ongoingScreenTypeData,
  ongoingRemoveScreenTypeData,
  setInitialPageIndexInitialScreening,
  setInitialPageSizeInitialScreening,
  resetInitialPageSizeInitialScreening,
  resetInitialPageIndexInitialScreening,
  setInitialPageIndexOngoingMonitoring,
  setInitialPageSizeOngoingMonitoring,
  resetInitialPageSizeOngoingMonitoring,
  resetInitialPageIndexOngoingMonitoring,
  setDownloadReportFilters,
  removeDownloadReportFilters,
  setHistoryFilters,
  setDownloadStatusFilters,
  setRiskRatingDashboardFilters,
  setExclusionListFilter,
  setAddToExclusionListFilter,
  setDashboardFilters,
  commonScreenTypeData,
  commonOngoingScreenTypeData,
  commonRemoveScreenTypeData,
  commonOngoingRemoveScreenTypeData
} from '../state/slices/commonSlice';

export default function useCommonReducer() {
  const dispatch = useDispatch();
  // @ts-ignore
  const { common } = useSelector((state) => state);
  return {
    dispatch,
    common,
    ongoingMonitoringTableView,
    initialScreeningTableView,
    screenTypeData,
    removeScreenTypeData,
    ongoingScreenTypeData,
    ongoingRemoveScreenTypeData,
    setInitialPageIndexInitialScreening,
    setInitialPageSizeInitialScreening,
    resetInitialPageSizeInitialScreening,
    resetInitialPageIndexInitialScreening,
    setInitialPageIndexOngoingMonitoring,
    setInitialPageSizeOngoingMonitoring,
    resetInitialPageSizeOngoingMonitoring,
    resetInitialPageIndexOngoingMonitoring,
    setDownloadReportFilters,
    removeDownloadReportFilters,
    setHistoryFilters,
    setDownloadStatusFilters,
    setRiskRatingDashboardFilters,
    setExclusionListFilter,
    setAddToExclusionListFilter,
    setDashboardFilters,
    commonScreenTypeData,
    commonOngoingScreenTypeData,
    commonRemoveScreenTypeData,
    commonOngoingRemoveScreenTypeData
  };
}
